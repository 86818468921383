import { Box } from '@mui/material';
import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calender.scss';

const Calender = () => {
  const [value, onChange] = useState(new Date());
  return (
    <Box className='calender_container'>
      <Calendar onChange={onChange} value={value} />
    </Box>
  )
}

export default Calender;