import React, { useEffect, useState } from "react";
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TextField,
  Button,
  Box,
  styled,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import AddNewUserDialog from "./AddNewUserDialog";
import { post } from "../../../Config/ApiConfig";
import CustomTableContainer from "../../../Components/customTable/CustomeTableContainer";

const ManageUser = () => {
  const [data, setData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); 
  const [pagination, setPagination] = useState({
    limit: 20,
    page: 1,
  });

  const handleClickOpen = () => {
    setSelectedUser(null);
    setDialogOpen(true);
  };

  const handleEditClickOpen = (user) => {
    setSelectedUser(user);
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedUser(null); 
  
  };

  const getAllUsers = () => {
    const formData = {
      keyword: "",
      role: null,
    };
    post(
      formData,
      `user/pagination/filter?limit=${pagination.limit}&page=${pagination.page}`
    )
      .then((resp) => {
        setData(resp.data.data);
      })
      .catch((err) => console.log(err));
  };

  const activeStatusOfUserById = (id, active) => {
    if (window.confirm("Are you sure you want to change user status?")) {
      post(null, `user/active?active=${!active}&id=${id}`)
        .then((res) => {
          if (res && res.status === "OK") {
            setData((prev) =>
              prev.map((item) =>
                item.id === id ? { ...item, active: !active } : item
              )
            );
          } else {
            console.log("Something went wrong");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);
 console.log(data);
  return (
    <Box sx={{ m: 1 }}>
      <Typography variant="h5" sx={{ fontWeight: "700" }}>
        Manage User
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography variant="h6" p={1}>
          Users List
        </Typography>
        <TextField
          type="text"
          placeholder="Search User"
          size="small"
          sx={{
            width: "250px",
            border: "none",
            "& .MuiOutlinedInput-root": {
              borderRadius: "20px",
              backgroundColor: "#F6F2F2",
              "& fieldset": {
                borderColor: "transparent",
              },
              "&:hover fieldset": {
                borderColor: "transparent",
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent",
              },
            },
            "& .MuiInputBase-input": {
              height: "10px",
              fontSize: "12px",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      {data.length > 0 && (
        <>
          <CustomTableContainer
            component={Paper}
            sx={{ maxHeight: "calc(100vh - 270px)", overflow: "auto" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Profile</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.fullName}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>
                      {item.role === 0 && "Admin"}
                      {item.role === 1 && "Lecturer"}
                      {item.role === 2 && "Student"}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        sx={{
                          width: "60px",
                          fontSize: "12px",
                          color: "var(--primary-theme-color)",
                          borderColor: "var(--primary-theme-color)",
                          backgroundColor: "var(--secondary-theme-color)",
                          "&:hover": {
                            backgroundColor: "var(--hover-background-color)",
                            borderColor: "var(--hover-border-color)",
                          },
                        }}
                        onClick={() => handleEditClickOpen(item)}
                      >
                        Update
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        sx={{
                          width: "80px",
                          fontSize: "12px",
                          color: item.active
                            ? "green"
                            : "var(--primary-theme-color)",
                          borderColor: item.active
                            ? "green"
                            : "var(--primary-theme-color)",
                          backgroundColor: "var(--secondary-theme-color)",
                          "&:hover": {
                            backgroundColor: "var(--hover-background-color)",
                            borderColor: "var(--hover-border-color)",
                          },
                        }}
                        onClick={() =>
                          activeStatusOfUserById(item.id, item.active)
                        }
                      >
                        {item.active ? "Active" : "Inactive"}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CustomTableContainer>
        </>
      )}
      <Box className="user_btn" sx={{ mt: 2 }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "var(--primary-theme-color)",
            padding: "8px 32px",
            borderRadius: "32px",
            float: "right",
            "&:hover": {
              backgroundColor: "var(--primary-theme-color)",
              opacity: 0.9,
            },
          }}
          onClick={handleClickOpen}
        >
          Add New User
        </Button>
      </Box>

      <AddNewUserDialog
        open={dialogOpen}
        handleClose={handleClose}
        refreshUserList={getAllUsers}
        initialValues={selectedUser}
      />
    </Box>
  );
};

export default ManageUser;
