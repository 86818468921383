import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../Common/Auth";
import ChangePasswordDialog from "./ChangePasswordDialog";

const RedirectComponent = () => {
  const { user } = useAuth();
  if (user && user.role === 0) {
    return "/admin/profile";
  } else if (user && user.role === 1) {
    return "/lecturer/profile";
  } else if (user && user.role === 2) {
    return "/student/profile";
  } else {
    return "no-data";
  }
};
const Settings = () => {
  const [open, setOpen] = useState(false);
  return (
    <Box sx={{ mx: 10 }}>
      <Box mt={1}>
        <Paper
          elevation={3}
          sx={{
            padding: "8px 16px",
            borderRadius: "16px",
            mb: 2,
          }}
        >
          <Typography variant="h6">Website Settings Overview</Typography>
        </Paper>
      </Box>

      {/* <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item>
            <Avatar
              alt="Remy Sharp"
              src="/demo-pic20.jpg"
              sx={{ height: "150px", width: "150px" }}
            />
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
              {user.fullName}
            </Typography>
            <Typography variant="body1">{user.email}</Typography>
          </Grid>
        </Grid>
      </Box> */}

      <Box>
        <Typography variant="p" color={"gray"}>
          The Settings section lets you control many facets of your website.
          Some of the elements that you can edit and manage from the Settings
          section are:
        </Typography>
      </Box>

      <Box>
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={RedirectComponent()}>
              <ListItemText primary="Profile" />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton onClick={() => setOpen(true)}>
              <ListItemText primary="Change Password" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
      <ChangePasswordDialog
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      />
    </Box>
  );
};

export default Settings;
