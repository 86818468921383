import React, { useEffect, useState } from "react"; 
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddCourseDialog from "./AddCourseDialog";
import {api_delete, get } from "../../../Config/ApiConfig";
import { Box, Button, Pagination } from "@mui/material";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import TimerIcon from "@mui/icons-material/Timer";
import PaginationItem from '@mui/material/PaginationItem';

const CourseManagement = () => {
  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [pagination, setPagination] = useState({
    limit: 100,
    page: 1,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [courseList, setCourseList] = useState([]);
  const [editableItemId, setEditableItemId] = useState(null);

  // Pagination handle
  const handlePagination = (event, value) => {
    setPagination((prev) => ({
      ...prev,
      page: value,
    }));
  };

  // Search Filter and API call
  const searchFilter = async () => {
    try {
      console.log("Fetching data for page:", pagination.page);
      const response = await get(
        `course/search?keyword=${keyword}&limit=${pagination.limit}&page=${pagination.page}`
      );

      if (response && response.status === "OK") {
        console.log("Response  page:", response.data);

        setTotalPages(response.data.totalPages); // Setting total pages for pagination
        setCourseList(response.data.data); // Updating the course list

        console.log("Total Pages: ", response.data.totalPages);
        console.log("Course List: ", response.data.data);
      } else {
        setCourseList([]); // If no data, reset course list
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  // useEffect to trigger search on pagination change
  useEffect(() => {
    console.log("Page nu:", pagination.page);
    searchFilter();  // Call searchFilter whenever pagination changes
  }, [pagination.page]);

  // Handle editing course
  const handleEditing = (id) => {
    setEditableItemId(id);
    setOpen(true);
  };

  const handleDelete = (id) => {
    console.log("id : ", id );
    api_delete(`course/${id}/delete`).then((response) => {
      console.log("Resp of del : ", response);
      if (response && response.status === "OK") {
        setCourseList((prev) => prev.filter((item) => item.id !== id));
      }
    });
  };

  return (
    <Box className="course_management_container">
      <Box className="management_table">
        {courseList.length > 0 && (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>No.</TableCell>
                  <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>Name</TableCell>
                  <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>Code</TableCell>
                  <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>Duration</TableCell>
                  <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>Credits</TableCell>
                  <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>Status</TableCell>
                  <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>Edit</TableCell>
                  <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>Delete</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {courseList.map((row, index) => (
                  <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.code}</TableCell>
                    <TableCell align="center">
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                        <TimerIcon sx={{ color: "var(--light-dark-color)" }} />
                        {row.duration} min
                      </Box>
                    </TableCell>
                    <TableCell align="center">{row.credits}</TableCell>
                    <TableCell align="center" sx={{ color: row.status === 1 ? "green" : "var( --light-dark-color)" }}>
                      {row.status === 1 ? "Active" : "Deactive"}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        sx={{
                          width: "80px",
                          fontSize: "12px",
                          color: "var(--primary-theme-color)",
                          borderColor: "var(--primary-theme-color)",
                          backgroundColor: "var(--secondary-theme-color)",
                          "&:hover": {
                            backgroundColor: "var(--hover-background-color)",
                            borderColor: "var(--hover-border-color)",
                          },
                        }}
                        onClick={() => handleEditing(row.id)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        sx={{
                          width: "80px",
                          fontSize: "12px",
                          color: "var(--primary-theme-color)",
                          borderColor: "var(--primary-theme-color)",
                          backgroundColor: "var(--secondary-theme-color)",
                          "&:hover": {
                            backgroundColor: "var(--hover-background-color)",
                            borderColor: "var(--hover-border-color)",
                          },
                        }}
                        onClick={() => handleDelete(row.id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      {/* Pagination Component */}
      <Pagination
        count={totalPages}
        page={pagination.page}
        onChange={handlePagination}
        sx={{
          ".MuiPaginationItem-text.MuiPaginationItem-page": {
            backgroundColor: "unset",
            border: "2px solid var(--light-dark-color)",
            boxSizing: "border-box",
            "&.Mui-selected": {
              backgroundColor: "var(--primary-theme-color)",
              color: "white",
              border: "unset",
            },
          },
        }}
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
            {...item}
          />
        )}
      />

      <Button
        variant="contained"
        sx={{
          mt: 2,
          mr: 4,
          backgroundColor: "var(--primary-theme-color)",
          padding: "8px 32px",
          borderRadius: "32px",
          float: "right",
          "&:hover": {
            backgroundColor: "var(--primary-theme-color)",
            opacity: 0.9,
          },
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        Add New Courses
      </Button>

      <AddCourseDialog
        open={open}
        handleClose={() => {
          setOpen(false);
          setEditableItemId(null);
        }}
        searchFilter={searchFilter}
        editableItemId={editableItemId}
      />
    </Box>
  );
};

export default CourseManagement;
