import React from "react";
import { Box, CircularProgress } from "@mui/material";

const LoadingCircle = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "rgba(0,0,0, 0.7)",
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
      }}
    >
      <CircularProgress thickness={5} sx={{color:"white"}} />
    </Box>
  );
};

export default LoadingCircle;
