import { Box, Button, Typography } from "@mui/material";
import React from "react";
import img404 from "../../Assets/Images/404img5.png";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background: "#F2F2F2",
        }}
      >
        <img src={img404} height={"480px"} width={"800px"} alt="404/img" />
        <Typography variant="h3" color="initial">
          Page Not Found
        </Typography>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "black",
            color: "white",
            marginTop: "20px",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.9)",
            },
          }}
          onClick={() => navigate('/')}
        >
          Go Home
        </Button>
      </Box>
    </Box>
  );
};

export default PageNotFound;
