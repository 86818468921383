import React, { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { get, post } from "../../../Config/ApiConfig";
import {
  DatePicker,
  LocalizationProvider,
  TimeField,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useSnackbar } from "../../../Common/Snackbar";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const daysList = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const AddCourseSectionDialog = ({
  open,
  handleClose,
  searchFilter,
  editableItemid,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    courseId: "",
    lecturerId: "",
    classroomId: "",
    capacity: "",
    days: [],
    startTime: dayjs().hour(0).minute(0),
    endTime: dayjs().hour(0).minute(0),
  });
  const [lecturerList, setLecturerList] = useState([]);
  const [coursesList, setCoursesList] = useState([]);
  const [classroomsList, setClassroomsList] = useState([]);
  const [lecturerFieldTouched, setLecturerFieldTouched] = useState(false);
  // const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const handleLecturerFocus = () => {
    setLecturerFieldTouched(true);
  };
  const { openSnackbar } = useSnackbar();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "days") {
      setFormData({
        ...formData,
        days: typeof value === "string" ? value.split(",") : value,
      });
    }
  };

  const handleStartTimeChange = (newValue) => {
    console.log(newValue.isBefore(formData.endTime));
    if (newValue && formData.endTime && newValue.isBefore(formData.endTime)) {
      setFormData({
        ...formData,
        startTime: newValue,
      });
    } else {
      openSnackbar("Start time must not be later than end time", "error");
    }
  };
  const handleEndTimeChange = (newValue) => {
    console.log(newValue.isAfter(formData.startTime));
    if (
      newValue &&
      formData.startTime &&
      newValue.isAfter(formData.startTime)
    ) {
      setFormData({
        ...formData,
        endTime: newValue,
      });
    } else {
      openSnackbar("End time must not be earlier than start time", "error");
    }
  };

  

  const handleDialogClose = () => {
    setFormData({
      name: "",
      courseId: "",
      lecturerId: "",
      classroomId: "",
      capacity: "",
      days: [],
      startTime: dayjs().hour(0).minute(0),
      endTime: dayjs().hour(0).minute(0),
    });
    setLecturerFieldTouched(false);
    handleClose();
  };

  useEffect(() => {
    console.log("formData", formData.days);
  }, [formData]);

  const handleSubmit = (e) => {
    const startTimeLong = new Date(
      2000,
      1,
      1,
      formData.startTime && formData.startTime.format("HH"),
      formData.startTime && formData.startTime.format("mm")
    );
    const endTimeLong = new Date(
      2000,
      1,
      1,
      formData.endTime && formData.endTime.format("HH"),
      formData.endTime && formData.endTime.format("mm")
    );
    const data = {
      capacity: formData.capacity,
      classroomId: formData.classroomId,
      courseId: formData.courseId,
      lecturerId: formData.lecturerId,
      name: formData.name,
      days: formData.days,
      startTime: startTimeLong.getTime(),
      endTime: endTimeLong.getTime(),
    };
    e.preventDefault();
    post(
      data,
      editableItemid && editableItemid !== null
        ? `section/update?id=${editableItemid}`
        : `section/add`
    )
      .then((res) => {
        if (res && res.status === "OK") {
          console.log("data save successfully");
          handleDialogClose();
          searchFilter();
          openSnackbar(res.message, "success");
        } else {
          openSnackbar(res.message, "error");
        }
      })
      .catch((err) => {
        openSnackbar("Something went wrong", "error");
      });
  };

  const getlecturers = (id) => {
    get(`user/lecturer/get?courseId=${id}`).then((response) => {
      if (response && response.status === "OK") {
        console.log('Lecturer id : ', id)
        console.log("lecturer list == ", response.data)
        setLecturerList(response.data);
      } else {
        setLecturerList([]);
      }
    });
  };
  const getCoursesList = () => {
    get("get/courses").then((response) => {
      if (response && response.status === "OK") {
        setCoursesList(response.data);
      } else {
        setCoursesList([]);
      }
    });
  };
  const getClassroomsList = () => {
    get("classrooms/get").then((response) => {
      if (response && response.status === "OK") {
        setClassroomsList(response.data);
      } else {
        setClassroomsList([]);
      }
    });
  };
  const getSectionById = (id) => {
    get(`get/section/${id}`).then((response) => {
      if (response && response.status === "OK") {
        setFormData({
          name: response.data.name,
          courseId: response.data.courseId,
          lecturerId: response.data.lecturerId,
          classroomId: response.data.classroomId,
          capacity: response.data.capacity,
          days: response.data.days,
          startTime: dayjs(new Date(response.data.startTime)),
          endTime: dayjs(new Date(response.data.endTime)),
        });
      }
    });
  };
  useEffect(() => {
    if (editableItemid) {
      getSectionById(editableItemid);
    }
    if (open) {
      getCoursesList();
      getClassroomsList();
    }
  }, [open]);

  useEffect(() => {
    if (formData.courseId && formData.courseId !== "") {
      getlecturers(formData.courseId);
    }
  }, [formData.courseId]);

  const [maximumCapacity, setMaximumCapacity] = useState(null);
  const getMaximumCapacity = (id) => {
    setMaximumCapacity(classroomsList.find((item) => item.id === id).capacity);
  };
  useEffect(() => {
    if (formData.classroomId !== null && formData.classroomId !== "") {
      getMaximumCapacity(formData.classroomId);
      setFormData({
        ...formData,
        capacity:
          classroomsList.find((item) => item.id === formData.classroomId).capacity >=
          formData.capacity
            ? formData.capacity
            : "",
      });
    }
  }, [formData.classroomId]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Dialog
          open={open}
          onClose={handleDialogClose}
          PaperProps={{
            component: "form",
            onSubmit: handleSubmit,
            sx: {
              backgroundColor: "#E5E4E2",
              width: "500px",
            },
          }}
        >
          <DialogTitle>
            {editableItemid && editableItemid !== null
              ? "Update Course"
              : "Add Course Section"}
          </DialogTitle>
          <Divider orientation="horizontal" />
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  required
                  id="name"
                  name="name"
                  label="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="courseId_lable">Course Name</InputLabel>
                  <Select
                    labelId="courseId_lable"
                    id="courseId"
                    name="courseId"
                    value={formData.courseId}
                    label="Course Name"
                    onChange={handleChange}
                  >
                    {coursesList.map((course) => (
                      <MenuItem key={course.id} value={course.id}>
                        {course.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="lecturerId_lable">lecturer Name</InputLabel>
                  <Select
                    labelId="lecturerId_lable"
                    id="lecturerId"
                    name="lecturerId"
                    value={formData.lecturerId}
                    label="lecturer Name"
                    onChange={handleChange}
                    onFocus={handleLecturerFocus}
                    readOnly={!formData.courseId}
                  >
                    {lecturerList.map((lecturer) => (
                      <MenuItem key={lecturer.id} value={lecturer.id}>
                        {lecturer.fullName}
                      </MenuItem>
                    ))}
                  </Select>
                  {!formData.courseId && lecturerFieldTouched && (
                    <FormHelperText>
                      Please select a course first
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="classroomId_lable">classroom Name</InputLabel>
                  <Select
                    labelId="classroomId_lable"
                    id="classroomId"
                    name="classroomId"
                    value={formData.classroomId}
                    label="classroom Name"
                    onChange={handleChange}
                  >
                    {classroomsList.map((classroom) => (
                      <MenuItem key={classroom.id} value={classroom.id}>
                        {classroom.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  required
                  id="capacity"
                  name="capacity"
                  label="capacity"
                  type="number"
                  value={formData.capacity}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (value <= maximumCapacity) {
                      handleChange(event);
                    }
                  }}
                  fullWidth
                  variant="outlined"
                  disabled={!formData.classroomId}
                  helperText={`capacity must be under ${maximumCapacity || 0}`}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="days-label">Days</InputLabel>
                  <Select
                    labelId="days-label"
                    id="days"
                    name="days"
                    multiple
                    value={formData.days}
                    onChange={handleChange}
                    input={<OutlinedInput label="Days" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                  >
                    {daysList.map((item) => (
                      <MenuItem key={item} value={item}>
                        <Checkbox checked={formData.days.indexOf(item) > -1} />
                        <ListItemText
                          sx={{ textTransform: "capitalize" }}
                          primary={item}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TimeField
                  label="Start Time"
                  value={formData.startTime}
                  onChange={handleStartTimeChange}
                  format="HH:mm"
                />
              </Grid>
              <Grid item xs={6}>
                <TimeField
                  label="End Time"
                  value={formData.endTime}
                  onChange={handleEndTimeChange}
                  format="HH:mm"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: "black" }} onClick={handleDialogClose}>
              Cancel
            </Button>
            <Button
              sx={{ color: "black" }}
              type="submit"
              // disabled={isButtonDisabled}
            >
              {editableItemid && editableItemid !== null ? "Update" : "Add New"}
            </Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    </>
  );
};

export default AddCourseSectionDialog;
