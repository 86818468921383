import React, { lazy, Suspense, useEffect, useRef } from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import LoadingCircle from "./Common/Loader/LoadingCircle";
import { AuthProvider, useAuth } from "./Common/Auth";
import RequiredAuth from "./Common/RequiredAuth";
import StudentDashboard from "./Pages/student/studentDashboard/StudentDashboard";
import CourseSchedule from "./Pages/student/CourseScheduling/CourseSchedule";
import StudentAssignedCourses from "./Pages/student/studentAvailableCourses/StudentAvailableCourses";
import AdjustAvailableSection from "./Pages/admin/CourseManagement/AdjustAvailableSection";
import ViewSectionOfCourses from "./Pages/student/studentAvailableCourses/ViewSectionOfCourses";
import Settings from "./Pages/settings/Settings";
import MySchedule from "./Pages/student/mySchedule/MySchedule";
import PageNotFound from "./Common/pageNotFound/PageNotFound";
import MyCourses from "./Pages/student/myCourses/MyCourses";
import Profile from "./Pages/settings/Profile";
import OfferedCourses from "./Pages/student/OfferedCourses/OfferedCourses";
import ModifyRegistration from "./Pages/student/studentAvailableCourses/ModifyRegistration";
import { SnackbarProvider } from "./Common/Snackbar";
import Admin from './Pages/admin/Admin';
import Dashboard from './Pages/admin/Dashboard/Dashboard';
import LecturerDashboard from './Pages/lecturer/lecturerDashboard/LecturerDashboard';
import ManageClassroom from './Pages/admin/CourseManagement/classroom/ManageClassroom';
import ManageCourseSection from './Pages/admin/CourseManagement/ManageCourseSection';
import CoursesList from './Pages/lecturer/lecturerDashboard/CoursesList';
import CourseManagement from './Pages/admin/CourseManagement/CourseManagement';
import CourseIndex from './Pages/admin/CourseManagement/CourseIndex';

import ManageUser from './Pages/admin/ManageUser/ManageUsers';
import LecturerDetails from './Pages/admin/ManageUser/LecturersDetails';

const Login = lazy(() => import("./Components/login/Login"));
// const Admin = lazy(() => import("./Pages/admin/Admin"));
// const Dashboard = lazy(() => import("./Pages/admin/Dashboard/Dashboard"));
// const CourseIndex = lazy(() =>
//   import("./Pages/admin/CourseManagement/CourseIndex")
// );
// const CourseManagement = lazy(() =>
//   import("./Pages/admin/CourseManagement/CourseManagement")
// );
// const CoursesList = lazy(() =>
//   import("./Pages/lecturer/lecturerDashboard/CoursesList"));
// const ManageCourseSection = lazy(() =>
//   import("./Pages/admin/CourseManagement/ManageCourseSection")
// );
// const ManageClassroom = lazy(() =>
//   import("./Pages/admin/CourseManagement/classroom/ManageClassroom")
// );
// const LecturerDashboard = lazy(() =>
//   import("./Pages/lecturer/lecturerDashboard/LecturerDashboard")
// );
// const ManageUser = lazy(() => import("./Pages/admin/ManageUser/ManageUsers"));
// const LecturerDetails = lazy(() => import("./Pages/admin/ManageUser/LecturersDetails"));


const RedirectComponent = () => {
  const { user } = useAuth();
  if (user && user.role === 0) {
    return <Navigate to="/admin/dashboard" replace />;
  } else if (user && user.role === 1) {
    return <Navigate to="/lecturer/dashboard" replace />;
  } else if (user && user.role === 2) {
    return <Navigate to="/student/dashboard" replace />;
  } else {
    return <Navigate to="/signin" replace />;
  }
};
const App = () => {
  return (
    <AuthProvider>
      <SnackbarProvider>
        <Routes>
          <Route
            path="/"
          >
            <Route index element={<RedirectComponent />} />
            {/* Admin Routing */}
            <Route
              path="admin"
              element={
                <RequiredAuth userRole={0}>
                  <Suspense fallback={<LoadingCircle />}>
                    <Admin />
                  </Suspense>
                </RequiredAuth>
              }
            >
              <Route index element={<Navigate to="dashboard" replace />} />
              <Route
                path="dashboard"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <Dashboard />
                  </Suspense>
                }
              />
              <Route
                path="courses"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <CourseIndex />
                  </Suspense>
                }
              >
                <Route
                  index
                  element={
                    <Suspense fallback={<LoadingCircle />}>
                      <CourseManagement />
                    </Suspense>
                  }
                />
                <Route
                  path="section"
                  element={
                    <Suspense fallback={<LoadingCircle />}>
                      <ManageCourseSection />
                    </Suspense>
                  }
                />


                <Route
                  path="classroom/assign"
                  element={
                    <Suspense fallback={<LoadingCircle />}>
                      <ManageClassroom />
                    </Suspense>
                  }
                />
                <Route
                  path="section/adjust-available"
                  element={
                    <Suspense fallback={<LoadingCircle />}>
                      <AdjustAvailableSection />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path="user/manage"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <ManageUser />
                  </Suspense>
                }
              />

              <Route
                path="profile"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <Profile />
                  </Suspense>
                }
              />
              <Route
                path="courses"
                element={
                  <Suspense fallback={<LoadingCircle />}>

                    {/* <CManagement /> */}
                  </Suspense>
                }
              />
              <Route
                path="settings"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <Settings />
                  </Suspense>
                }
              />
            </Route>
            {/* Lecturer Routing  */}
            <Route
              path="lecturer"
              element={
                <RequiredAuth userRole={1}>
                  <Suspense fallback={<LoadingCircle />}>
                    <Admin />
                  </Suspense>
                </RequiredAuth>
              }
            >
              <Route
                index
                element={<Navigate to="/lecturer/dashboard" replace />}
              />
              <Route
                path="dashboard"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <LecturerDashboard />
                  </Suspense>
                }
              />
              <Route
                path="profile"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <Profile />
                  </Suspense>
                }
              />
              <Route
                path="user/manage"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <LecturerDetails />
                  </Suspense>
                }
              />
              <Route
                path="user/course"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <CoursesList />
                  </Suspense>
                }
              />
              <Route
                path="settings"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <Settings />
                  </Suspense>
                }
              />
            </Route>
            {/* Studnet Routing  */}

            <Route
              path="student"
              element={
                <RequiredAuth userRole={2}>
                  <Suspense fallback={<LoadingCircle />}>
                    <Admin />
                  </Suspense>
                </RequiredAuth>
              }
            >
              <Route
                index
                element={<Navigate to="/student/dashboard" replace />}
              />
              <Route
                path="dashboard"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <StudentDashboard />
                  </Suspense>
                }
              />
              <Route
                path="course/registration"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <StudentAssignedCourses />
                  </Suspense>
                }
              />
              <Route
                path="modify/course/registration"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <ModifyRegistration />
                  </Suspense>
                }
              />
              <Route
                path="course/registration/view/:courseid"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <ViewSectionOfCourses />
                  </Suspense>
                }
              />
              <Route
                path="courses/schedule"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <CourseSchedule />
                  </Suspense>
                }
              />
              <Route
                path="my-schedules"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <MySchedule />
                  </Suspense>
                }
              />
              <Route
                path="my-courses"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <MyCourses />
                  </Suspense>
                }
              />
              <Route
                path="offered-courses"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <OfferedCourses />
                  </Suspense>
                }
              />
              <Route
                path="profile"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <Profile />
                  </Suspense>
                }
              />
              <Route
                path="user/manage"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <LecturerDetails />
                  </Suspense>
                }
              />
              <Route
                path="settings"
                element={
                  <Suspense fallback={<LoadingCircle />}>
                    <Settings />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="signin"
              element={
                <Suspense fallback={<LoadingCircle />}>
                  <Login />
                </Suspense>
              }
            />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </SnackbarProvider>
    </AuthProvider>
  );
};

export default App;
