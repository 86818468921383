import React, { useEffect, useState } from "react";
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Box,
  
} from "@mui/material";

import AddNewUserDialog from   "./AddNewUserDialog";
import { get, post } from "../../../Config/ApiConfig";
import CustomTableContainer from "../../../Components/customTable/CustomeTableContainer";

const LecturerDetails = () => {
  const [data, setData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); 
  
  const handleClose = () => {
    setDialogOpen(false);
    setSelectedUser(null); 
  
  };

  const getAllUsers = () => {
    get("user/all/lecturers/get").then((response) => {
      if (response && response.status === "OK") {
        setData(response.data);
      } else {
        setData([]);
      }
    });
  };
   

  useEffect(() => {
    getAllUsers();
  }, []);
 console.log(data);
  return (
    <Box sx={{ m: 1 }}>
      <Typography variant="h5" sx={{ fontWeight: "700" }}>
         Lecturers List
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography variant="h6" p={1}>
          
        </Typography>
        <Typography variant="h6" p={1}>
          
        </Typography>
        
      </Box>
      {data.length > 0 && (
        <>
          <CustomTableContainer
            component={Paper}
            sx={{ maxHeight: "calc(100vh - 270px)", overflow: "auto" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>No</TableCell>
                  <TableCell>Profile</TableCell>
                  <TableCell>Email</TableCell>
                 
                
                  <TableCell>Specializations</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.fullName}</TableCell>
                    <TableCell>{item.email}</TableCell>
                   
                   
                    <TableCell>
                    {item.specializations.map((spec) => spec.specialization).join(", ")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CustomTableContainer>
        </>
      )}
      
      <AddNewUserDialog
        open={dialogOpen}
        handleClose={handleClose}
        refreshUserList={getAllUsers}
        initialValues={selectedUser}
      />
    </Box>
  );
};

export default LecturerDetails;
