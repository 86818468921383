import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Button, Checkbox, styled, Typography } from "@mui/material";
import TimerIcon from "@mui/icons-material/Timer";
import { NavLink, useNavigate } from "react-router-dom";
import { get, post } from "../../../Config/ApiConfig";
import CustomTableContainer from "../../../Components/customTable/CustomeTableContainer";
const StudentAssignedCourses = () => {
  const navigate = useNavigate();
  const [selectedCoursesList, setSelectedCoursesList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 20,
    page: 1,
  });
  const handleCheckboxChange = (event, course) => {
    if (event.target.checked) {
      setSelectedCoursesList((prevList) => [...prevList, course]);
    } else {
      setSelectedCoursesList((prevList) =>
        prevList.filter((selectedCourse) => selectedCourse !== course)
      );
    }
  };

  const getCoursesList = () => {
    const keyword = "";
    get(
      `course/search?&keyword=${keyword}&limit=${pagination.limit}&page=${pagination.page}`
    ).then((response) => {
      if (response && response.status === "OK") {
        setCourseList(response.data.data);
      } else setCourseList([]);
    });
  };
  useEffect(() => {
    console.log("list", selectedCoursesList);
  }, [selectedCoursesList]);
  useEffect(() => {
    getCoursesList();
  }, []);

  return (
    <>
      <Paper sx={{ m: 2, p: 1 }}>
        <Box>
          <Typography variant="h5" color="black">
            Available Courses
          </Typography>
          <Box>
            <Typography variant="h6" color="gray">
              All Courses
            </Typography>
            <CustomTableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Course Name</TableCell>
                    <TableCell align="center">Course Code</TableCell>
                    <TableCell align="center">Course Hours</TableCell>
                    <TableCell align="center">Section</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {courseList.map((row, index) => (
                    <TableRow key={index + 1}>
                      <TableCell>
                        <Checkbox
                          sx={{
                            "& .MuiIconButton-root": {
                              backgroundColor: "#740025",
                            },
                            "&.Mui-checked": {
                              color: "#740025",
                            },
                          }}
                          size="small"
                          onChange={(event) =>
                            handleCheckboxChange(event, row.id)
                          }
                          checked={selectedCoursesList.some(
                            (selectedCourse) => selectedCourse === row.id
                          )}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "#740025",
                            padding: "20px",
                            borderRadius: "50%",
                          }}
                        ></Box>
                        {row.name}
                      </TableCell>
                      <TableCell align="center">{row.code}</TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <TimerIcon sx={{ color: "gray" }} />
                          {row.duration}&nbsp;min
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          sx={{
                            height: "25px",
                            width: "80px",
                            borderRadius: "20px",
                            border: "1px solid #740025",
                            color: "gray",
                            fontSize: "13px",
                            textTransform: "lowercase",
                            fontWeight: 400,
                            "&:hover": {
                              border: "1px solid #5c001a",
                              bgcolor: "#5c001a",
                              color: "white",
                            },
                          }}
                          onClick={() => {
                            navigate(
                              `/student/course/registration/view/${row.id}`
                            );
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTableContainer>
          </Box>
        </Box>
      </Paper>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          align={"right"}
          sx={{
            mt: 2,
            mb: 2,
            mr: 4,
            marginLeft: "auto",
            backgroundColor: "var(--primary-theme-color)",
            padding: "8px 32px",
            borderRadius: "32px",
            "&:hover": {
              backgroundColor: "var(--primary-theme-color)",
              opacity: 0.9,
            },
          }}
          component={NavLink}
          to={"/student/courses/schedule"}
          state={selectedCoursesList}
          disabled={selectedCoursesList.length <= 0}
        >
          Student Course Scheduler
        </Button>
      </Box>
    </>
  );
};

export default StudentAssignedCourses;
