import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom';


import DashboardCourseCard from '../../../Components/dashboardCard/DashboardCourseCard';

import menuImg from '../../../Assets/Images/menu icon.png';
import menuBarImg from '../../../Assets/Images/second.png';
import MultiplePerson from '../../../Assets/Images/multiplePerson.png';
import mike from '../../../Assets/Images/mike.png';

const studentDashboardCourseCards = [
  {
    icon: menuImg,
    title: 'Course Registration',
    pageLink: '/student/course/registration',
  },
  {
    icon: menuBarImg,
    title: 'Modify Registration',
    pageLink: '/student/modify/course/registration',
  },
  {
    icon: MultiplePerson,
    title: 'Offered Courses',
    pageLink: '/student/offered-courses',
  },
  {
    icon: mike,
    title: 'Schedule',
    pageLink: '/student/my-schedules',
  },

  {
    icon: menuImg,
    title: 'My Courses',
    pageLink: '/student/my-courses',
  },
  
];


const StudentDashboard = () => {
  return (
    <Box>
      <Typography variant="h5" color="gray">My Courses</Typography>
      <Grid container spacing={2} mt={2}>
        {studentDashboardCourseCards.map((data, index) => (
          <Grid item key={index} xs={4} m={0}>
            <DashboardCourseCard icon={data.icon} title={data.title} pageLink={data.pageLink} />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default StudentDashboard