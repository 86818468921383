import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Typography } from "@mui/material";
import { get } from "../../../Config/ApiConfig";
import TimerIcon from "@mui/icons-material/Timer";
export default function OfferedCourses() {
  const [courseList, setCourseList] = useState([]);
  const isMount = useRef(false);
  const getCourses = () => {
    get("get/courses").then((response) => {
      if (response && response.status === "OK") {
        setCourseList(response.data);
      }
    });
  };

  useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
      getCourses();
    }
  }, []);
  return (
    <>
      <Typography variant="h6" mb={3}>
        Offered Courses
      </Typography>
      {courseList.length > 0 && (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{ color: "var(--light-dark-color)" }}
                >
                  No.
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "var(--light-dark-color)" }}
                >
                  Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "var(--light-dark-color)" }}
                >
                  Code
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "var(--light-dark-color)" }}
                >
                  Duration
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ color: "var(--light-dark-color)" }}
                >
                  Credits
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {courseList.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.code}</TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <TimerIcon sx={{ color: "var(--light-dark-color)" }} />
                      {row.duration} min
                    </Box>
                  </TableCell>
                  <TableCell align="center">{row.credits}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
