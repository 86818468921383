import React from "react";
import "./sidebar.scss";
import { NavLink } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// import CManagement from '/Users/mazeedjoya/Downloads/utm/src/Pages/admin/CManagement/CManagement'
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useAuth } from "../Auth";

const Sidebar = () => {
  const { user } = useAuth();
  return (
    <Box
      sx={{ width: "100%", bgcolor: "background.paper" }}
      className="sidebar_container"
    >
      <List>
        {user && user.role === 0 && (
          <>
            <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/admin/dashboard">
                <ListItemIcon>
                  <DashboardOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/admin/courses">
                <ListItemIcon>
                  <ImportContactsIcon />
                </ListItemIcon>
                <ListItemText primary="Course Management" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/admin/user/manage">
                <ListItemIcon>
                  <ImportContactsIcon />
                </ListItemIcon>
                <ListItemText primary="Manage User Account" />
              </ListItemButton>
            </ListItem> 

            {/* <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/admin/courses"> 
                <ListItemIcon>
                  <ImportContactsIcon />
                </ListItemIcon>
                <ListItemText primary="C  Management" />
              </ListItemButton>
            </ListItem> */}

            <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/admin/settings">
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </ListItem>

          </>
        )}

        {user && user.role === 1 && (
          <>
            <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/lecturer/dashboard">
                <ListItemIcon>
                  <DashboardOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/lecturer/profile">
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Profile update" />
              </ListItemButton>
            </ListItem>
                 {/*  yaha change  */}
            <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/lecturer/user/manage">
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Lecturers" />
              </ListItemButton>
            </ListItem>
            
            <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/lecturer/user/course">
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Courses" />
              </ListItemButton>
            </ListItem>
             {/*  yaha change  */}

            <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/lecturer/settings">
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </ListItemButton>
            </ListItem>
          </>
        )}
        {user && user.role === 2 && (
          <>
            <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/student/dashboard">
                <ListItemIcon>
                  <DashboardOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                component={NavLink}
                to="/student/course/registration"
              >
                <ListItemIcon>
                  <MenuBookIcon />
                </ListItemIcon>
                <ListItemText primary="Course Registration" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/student/modify/course/registration">
                <ListItemIcon>
                  <MenuBookIcon />
                </ListItemIcon>
                <ListItemText primary="Modify Registration" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/student/offered-courses">
                <ListItemIcon>
                  <MenuBookIcon />
                </ListItemIcon>
                <ListItemText primary="Offered Couses" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/student/my-courses">
                <ListItemIcon>
                  <MenuBookIcon />
                </ListItemIcon>
                <ListItemText primary="My courses" />
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/student/my-schedules">
                <ListItemIcon>
                  <EventNoteRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Schedule" />
              </ListItemButton>
            </ListItem>
              {/*  yaha change  */}
              <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/student/user/manage">
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Lecturers" />
              </ListItemButton>
            </ListItem>
               {/*  yaha change  */}
            <ListItem disablePadding>
              <ListItemButton component={NavLink} to="/student/settings">
                <ListItemIcon>
                  <SettingsOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="Setting" />
              </ListItemButton>
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );
};

export default Sidebar;
