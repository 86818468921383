import React from "react";
import { Typography, Box } from "@mui/material";
import { NavLink } from "react-router-dom";

const DashboardCourseCard = ({ icon, title, pageLink }) => {
  return (
    <Box
      component={NavLink}
      to={pageLink}
      sx={{
        height: "180px",
        width: "190px",
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        background: "transparent",
        padding: "2px",
        boxShadow: "0 0 2px 0.5px #ececec",
        transaction: "boxShadow 5s",
        textDecoration: "none",
        color: "black !important",
        "&:hover": {
          backgroundColor: "var(--primary-theme-color)",
          color: "white !important",
          transition: "backgrounColor 0.5s ease",
          // boxShadow:  '11px 11px 65px #696969,-11px -11px 65px #ffffff',
          boxShadow: "29.5px 25.5px 24.5px 6.5px #dedede",
          "#title": {
            color: "white !important",
          },
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: "#EEE1E6",
          height: "40px",
          width: "40px",
          borderRadius: "50%",
          position: "relative",
          marginLeft: "10px",
        }}
      >
        <img
          src={icon}
          style={{
            height: "30px",
            width: "30px",
            position: "absolute",
            right: "0",
          }}
          alt="Icon"
        />
      </Box>
      <Typography
        variant="body1"
        align="center"
        id="title"
        sx={{
          fontSize: "15px",
          fontWeight: "550",
          textAlign: "start",
          display: "block",
          marginBottom: "20px",
          marginX: "15px",
          transistion: "color 5s",
          color: "black !important",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default DashboardCourseCard;
