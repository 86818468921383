import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

export default function ScheduleTable({ days, timeSlots, schedule }) {
  useEffect(() => {
    console.log("days, timeSlots, schedule", days, timeSlots, schedule);
  }, [days, timeSlots, schedule]);
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                  width: "calc(100% / 7)",
                }}
              >
                Day
              </TableCell>
              {days &&
                days.map((day, index) => (
                  <TableCell
                    key={day}
                    sx={{
                      borderRight:
                        days.length - 1 !== index &&
                        "1px solid rgba(224, 224, 224, 1)",
                      width: "calc(100% / 7)",
                    }}
                  >
                    {day}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {timeSlots &&
              timeSlots.map((timeslot) => (
                <TableRow key={timeslot}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                      width: "calc(100% / 7)",
                    }}
                  >
                    {timeslot}
                  </TableCell>
                  {days &&
                    days.map((day, index) => (
                      <TableCell
                        key={day}
                        sx={{
                          borderRight:
                            days.length - 1 !== index &&
                            "1px solid rgba(224, 224, 224, 1)",
                          width: "calc(100% / 7)",
                          whiteSpace: "pre-wrap", 
                        }}
                      >
                        {schedule[day] && schedule[day][timeslot]}
                      </TableCell>
                    ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
