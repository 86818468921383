import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Grid } from "@mui/material";
import { get, post } from "../../../../Config/ApiConfig";

export default function AddClassroomDialog({
  open,
  handleClose,
  searchFilter,
  editableItemId,
}) {
  const [formData, setFormData] = useState({
    name: "",
    capacity: "",
  });
  const [lecturerList, setLecturerList] = useState([]);
  const [coursesList, setCoursesList] = useState([]);
  const [classroomsList, setClassroomsList] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    post(
      formData,
      editableItemId && editableItemId !== null
        ? `classroom/update?id=${editableItemId}`
        : `classroom/add`
    )
      .then((res) => {
        if (res && res.status === "OK") {
          console.log("data save successfully");
          handleClose();
          searchFilter();
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getClassroomById = (id) => {
    get(`get/classroom/${id}`).then((response) => {
      if (response && response.status === "OK") {
        setFormData({
          name: response.data.name,
          courseId: response.data.courseId,
          lecturerId: response.data.lecturerId,
          classroomId: response.data.classroomId,
          availableSpace: response.data.availableSpace,
          capacity: response.data.capacity,
          time: response.data.time,
        });
      }
    });
  };
  useEffect(() => {
    if (open && editableItemId) {
      getClassroomById(editableItemId);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit,
          sx: {
            backgroundColor: "#E5E4E2",
            width: "500px",
          },
        }}
      >
        <DialogTitle>
          {editableItemId && editableItemId !== null
            ? `Update Classroom `
            : `Add New Classroom`}
        </DialogTitle>
        <Divider orientation="horizontal" />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                required
                id="name"
                name="name"
                label="name"
                type="text"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                id="capacity"
                name="capacity"
                label="capacity"
                type="number"
                value={formData.capacity}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "black" }} onClick={handleClose}>
            Cancel
          </Button>
          <Button sx={{ color: "black" }} type="submit">
            {editableItemId && editableItemId !== null ? "Update" : "Add New"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
