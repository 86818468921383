import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { get } from "../../../Config/ApiConfig";
import { Box, Typography } from "@mui/material";
import TimerIcon from "@mui/icons-material/Timer";

const CoursesList = () => {
  const [keyword, setKeyword] = useState("");
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
  });
  const prevPagination = useRef(0);

  const [courseList, setCourseList] = useState([]);

  const searchFilter = () => {
    try {
      get(
        `course/search?keyword=${keyword}&limit=${pagination.limit}&page=${pagination.page}`
      ).then((response) => {
        if (response && response.status === "OK") {

          setCourseList(response.data.data);
        } else {
          setCourseList([]);
        }
      });
    } catch (error) {
      console.error("Error on Search Fiter", error);
    }
  };
  useEffect(() => {
    if (prevPagination.current < pagination.page) {
      prevPagination.current = pagination.page;
      searchFilter();
    }
  }, [pagination]);


  return (
    <Box className="course_management_container">
        <Typography variant="h5" sx={{ mb: 1 }}>
            Courses
        </Typography>
      <Box className="management_table">
        {courseList.length > 0 && (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ color: "var(--light-dark-color)" }}
                  >
                    No.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "var(--light-dark-color)" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "var(--light-dark-color)" }}
                  >
                    Code
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "var(--light-dark-color)" }}
                  >
                    Duration
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "var(--light-dark-color)" }}
                  >
                    Credits
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "var(--light-dark-color)" }}
                  >
                    Status
                  </TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>
                {courseList.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >  
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.code}</TableCell>
                    <TableCell align="center">
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        <TimerIcon sx={{ color: "var(--light-dark-color)" }} />
                        {row.duration} min
                      </Box>
                    </TableCell>
                    <TableCell align="center">{row.credits}</TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        color:
                          row.status === 1
                            ? "green"
                            : "var( --light-dark-color)",
                      }}
                    >
                      {row.status === 1 ? "Active" : "Deactive"}
                    </TableCell>
                    
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  );
};

export default CoursesList;
