import { Box, Paper, Typography } from '@mui/material'
import React from 'react';

const DashboardCard = ({ data }) => {
  const {title, icon, count, percentage, time} =data;
  return (
    <Paper elevation={5} sx={{ borderRadius: '16px', p: 1,}}>
      <Box sx={{p:1,display:'flex',flexDirection:'column',}}>
        <Typography variant="h6" color="initial" sx={{fontWeight:'700'}}>{title}</Typography>
        <Box sx={{display:'flex',gap:2,alignItems:'center',margin:'10px 0px'}}>
        <Typography sx={{backgroundColor:"var(--primary-theme-color)",borderRadius:'50%',padding:'5px'}} variant="span" color="white">{icon}</Typography>
        <Typography sx={{fontSize:'1.5rem',fontWeight:'700',color:"var(--primary-theme-color)"}} variant="h6">{count}</Typography>
        </Box>
        <Typography variant="p" color="initial">
          <Typography variant="span" sx={{color: percentage >= 0 ? 'green' : 'var(--primary-theme-color)'}}>{Math.abs(percentage)}%</Typography>&nbsp;
          <Typography variant="span">
            {percentage >= 0 ? `higher than last ${time}` : `less than last ${time}`}
          </Typography>
        </Typography>
      </Box>
    </Paper>
  )
}

export default DashboardCard;