import React from "react";
import "../../Styles/Navigation.scss";
import SearchIcon from "@mui/icons-material/Search";
import MessageIcon from "@mui/icons-material/Message";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useAuth } from "../../Common/Auth";
import { NavLink, useNavigate } from "react-router-dom";

// import { AppBar, Toolbar, IconButton, InputBase, Box, Typography, Accordion, AccordionSummary, AccordionDetails, MenuItem, List, ListItem } from "@mui/material";

const RedirectComponent = () => {
  const { user } = useAuth();
  if (user && user.role === 0) {
    return "/admin/profile";
  } else if (user && user.role === 1) {
    return "/lecturer/profile";
  } else if (user && user.role === 2) {
    return "/student/profile";
  } else {
    return "no-data";
  }
};
const NavigationBar = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { user, logout } = useAuth();

  return (
    <nav
      className="nav_container"
      style={{ backgroundColor: "white", zIndex: 1000 }}
    >
      {/* icon */}
      <div className="nav_icon">
        <h4>UTM</h4>
      </div>
      {/* search */}
      <div className="nav_search">
        <span>
          <input type="text" placeholder="search for your keyword ..." />
          <SearchIcon sx={{ p: 1, fontSize: "20px" }} />
        </span>
      </div>
      {/* other icons */}
      <div className="nav_icon_btn">
        <span>
          <MessageIcon sx={{ height: "25px", color: "gray" }} />{" "}
        </span>
        <div className="vertical_line"></div>
        <span>
          <NotificationsNoneOutlinedIcon
            sx={{ height: "25px", color: "gray" }}
          />
        </span>
        <div className="vertical_line"></div>

        <div className="black_box"></div>
        {user ? (
          <>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              sx={{ color: "black" }}
              endIcon={
                <KeyboardArrowDownIcon
                  sx={{ color: "var(--primary-theme-color)" }}
                />
              }
            >
              {user.fullName}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem component={NavLink} to={RedirectComponent()}>
                Profile
              </MenuItem>
              {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Button
              onClick={() => {
                navigate("/signin");
              }}
            >
              login
            </Button>
          </>
        )}
      </div>
    </nav>
  );
};

export default NavigationBar;
