import React, { useEffect, useRef, useState } from "react";
import ScheduleTable from "../../../Components/scheduleTable/ScheduleTable";
import { Box, Pagination, PaginationItem, Typography } from "@mui/material";
import { get } from "../../../Config/ApiConfig";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
export default function MySchedule() {
  const [days, setDays] = useState(null);
  const [timeSlots, setTimeSlots] = useState(null);
  const [scheduleList, setScheduleList] = useState([]);
  const isMount = useRef(false);

  const getSchedule = () => {
    get("get/schedule/registrations").then((response) => {
      if (response && response.status === "OK") {
        setDays(response.data.days);
        setTimeSlots(response.data.timeSlots);
        setScheduleList(response.data.schedules[0]);
        // if (response.data.schedules.length >= 0) {
        //   setCurrentPage(1);
        // }
      }
    });
  };
  // const handleCurrentPageChange = (event, value) => {
  //   setCurrentPage(value);
  // };

  useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
      getSchedule();
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          // display: "flex",
          // justifyContent: "space-between",
          // alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h5" sx={{ mb: 1 }}>
          My Schedule
        </Typography>
        {/* <Box>
          <Pagination
            count={scheduleList.length}
            sx={{
              ".MuiPaginationItem-text.MuiPaginationItem-page": {
                backgroundColor: "unset",
                border: "2px solid var(--light-dark-color)",
                boxSizing: "border-box",
                "&.Mui-selected": {
                  backgroundColor: "var(--primary-theme-color)",
                  color: "white",
                  border: "unset",
                },
              },
            }}
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
                {...item}
              />
            )}
            page={currentpage}
            onChange={handleCurrentPageChange}
          />
        </Box> */}
      </Box>
      {scheduleList ? (
        <>
          <ScheduleTable
            days={days}
            timeSlots={timeSlots}
            schedule={scheduleList}
          />
        </>
      ) : (
        <>
          <Typography variant="h6">No Schedule Yet</Typography>
        </>
      )}
    </>
  );
}
