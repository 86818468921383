import { Box, Typography, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { get, post } from "../../../Config/ApiConfig";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TimerIcon from "@mui/icons-material/Timer";
import { useSnackbar } from '../../../Common/Snackbar' ;


const colCell = ['No.', 'Name', 'Code', 'Duration', 'credits', 'Course Change']
export default function MyCourses() {
  const { openSnackbar } = useSnackbar();
  const isMount = useRef(false);
  const [courseList, setCourseList] = useState([]);
  const getMyCourses = () => {
    get("get/registered/courses").then((response) => {
      if (response && response.status === "OK") {
        setCourseList(response.data)
      }
    });
  };

  useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
      getMyCourses();
    }
  });

 
  const courseChange = (id) => {
   
    if (window.confirm(`  Are you sure you want to change Course ?`)) {
       
      post(null, `course/${id}/change/request`)
      .then((res) => {
        if (res && res.status === "OK") {
          openSnackbar(res.message);
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => console.log(err));
      
    }
  };
  return (
    <>
      <Box>
        <Typography variant="h5" sx={{ mb: 1 }}>
          My Courses
        </Typography>

        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {colCell.map((item) => (
                  <TableCell
                  align="center"
                  sx={{ color: "var(--light-dark-color)" }}
                >
                  {item}
                </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {courseList.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.code}</TableCell>
                  <TableCell align="center">
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <TimerIcon sx={{ color: "var(--light-dark-color)" }} />
                      {row.duration} min
                    </Box>
                  </TableCell>
                  <TableCell
                    align="center"
                  >
                    {row.credits}
                  </TableCell>
                  <TableCell align="center">
                  <Button
                        variant="contained"
                        sx={{
                          width: "130px",
                          height: "25px",
                          color: "white",
                          fontSize: "10px",
                          backgroundColor:
                            "var(--primary-theme-color, #740025)",
                          "&:hover": {
                            backgroundColor: "var(--primary-theme-color)",
                          },
                        }}
                        onClick={() => courseChange(row.id)}
                      >
                       Change Course
                  </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
