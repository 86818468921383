import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Checkbox,
  Pagination,
  PaginationItem,
  styled,
  Typography,
} from "@mui/material";
import TimerIcon from "@mui/icons-material/Timer";
import { NavLink, useNavigate } from "react-router-dom";
import { get, post } from "../../../Config/ApiConfig";
import CustomTableContainer from "../../../Components/customTable/CustomeTableContainer";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

export default function ModifyRegistration() {
  const navigate = useNavigate();
  const [selectedCoursesList, setSelectedCoursesList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
  });
  const prevPagination = useRef(0);
  const handlePagination = (event, value) => {
    setPagination((prev) => ({
      ...prev,
      page: value,
    }));
  };
  const [totalPages, setTotalPages] = useState(0);
  const isMount = useRef(false);
  const handleCheckboxChange = (event, course) => {
    if (event.target.checked) {
      setSelectedCoursesList((prevList) => [...prevList, course]);
    } else {
      setSelectedCoursesList((prevList) =>
        prevList.filter((selectedCourse) => selectedCourse !== course)
      );
    }
  };

  const getAlreadyRegisteredCourses = () => {
    get("get/registered/courses/info").then((response) => {
      if (response && response.status === "OK") {
        setSelectedCoursesList(response.data);
      }
    });
  };

  const getCoursesList = () => {
    const keyword = "";
    get(
      `course/search?&keyword=${keyword}&limit=${pagination.limit}&page=${pagination.page}`
    ).then((response) => {
      if (response && response.status === "OK") {
        setTotalPages(response.data.totalPages);
        setCourseList((prev) => [...prev, ...response.data.data]);
      } else setCourseList([]);
    });
  };
  useEffect(() => {
    console.log("list", selectedCoursesList);
  }, [selectedCoursesList]);
  useEffect(() => {
    if (!isMount.current) {
      isMount.current = true;
      getAlreadyRegisteredCourses();
    }
  }, []);
  useEffect(() => {
    if (prevPagination.current < pagination.page) {
      prevPagination.current = pagination.page;
      getCoursesList();
    }
  }, [pagination]);

  const registrationCourses = () => {
    if (
      window.confirm(
        "change registration may effect your previous registred schedule"
      )
    )
      navigate("/student/courses/schedule", { state: selectedCoursesList });
  };

  return (
    <>
      <Paper sx={{ m: 2, p: 1 }}>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5" sx={{ mb: 1 }}>
              Modify Registration
            </Typography>
            <Box>
              <Pagination
                count={totalPages}
                sx={{
                  ".MuiPaginationItem-text.MuiPaginationItem-page": {
                    backgroundColor: "unset",
                    border: "2px solid var(--light-dark-color)",
                    boxSizing: "border-box",
                    "&.Mui-selected": {
                      backgroundColor: "var(--primary-theme-color)",
                      color: "white",
                      border: "unset",
                    },
                  },
                }}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
                    {...item}
                  />
                )}
                page={pagination.page}
                onChange={handlePagination}
              />
            </Box>
          </Box>
          <Box>
            <Typography variant="h6" color="gray">
              All Courses
            </Typography>
            <CustomTableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Course Name</TableCell>
                    <TableCell align="center">Course Code</TableCell>
                    <TableCell align="center">Course Hours</TableCell>
                    <TableCell align="center">Section</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {courseList.map((row, index) => (
                    <TableRow key={index + 1}>
                      <TableCell>
                        <Checkbox
                          sx={{
                            "& .MuiIconButton-root": {
                              backgroundColor: "#740025",
                            },
                            "&.Mui-checked": {
                              color: "#740025",
                            },
                          }}
                          size="small"
                          onChange={(event) =>
                            handleCheckboxChange(event, row.id)
                          }
                          checked={selectedCoursesList.some(
                            (selectedCourse) => selectedCourse === row.id
                          )}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: "#740025",
                            padding: "20px",
                            borderRadius: "50%",
                          }}
                        ></Box>
                        {row.name}
                      </TableCell>
                      <TableCell align="center">{row.code}</TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <TimerIcon sx={{ color: "gray" }} />
                          {row.duration}&nbsp;min
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="outlined"
                          sx={{
                            height: "25px",
                            width: "80px",
                            borderRadius: "20px",
                            border: "1px solid #740025",
                            color: "gray",
                            fontSize: "13px",
                            textTransform: "lowercase",
                            fontWeight: 400,
                            "&:hover": {
                              border: "1px solid #5c001a",
                              bgcolor: "#5c001a",
                              color: "white",
                            },
                          }}
                          onClick={() => {
                            navigate(
                              `/student/course/registration/view/${row.id}`
                            );
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTableContainer>
          </Box>
        </Box>
      </Paper>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          align={"right"}
          sx={{
            mt: 2,
            mb: 2,
            mr: 4,
            marginLeft: "auto",
            backgroundColor: "var(--primary-theme-color)",
            padding: "8px 32px",
            borderRadius: "32px",
            "&:hover": {
              backgroundColor: "var(--primary-theme-color)",
              opacity: 0.9,
            },
          }}
          // component={NavLink}
          // to={"/student/courses/schedule"}
          // state={selectedCoursesList}
          disabled={selectedCoursesList.length <= 0}
          onClick={registrationCourses}
        >
          Student Course Scheduler
        </Button>
      </Box>
    </>
  );
}
