import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAuth } from "../../../Common/Auth";
import { get ,post} from "../../../Config/ApiConfig";
import ViewCoursesDialog from "./ViewCoursesDialog";
import AddCourseSectionDialog from  '../../admin/CourseManagement/AddCourseSectionDialog' 
import { useSnackbar } from '../../../Common/Snackbar' 

export default function LecturerDashboard() {
  const { user } = useAuth();
  const [open, setOpen] = useState(false); 
  const [editableItem, setEditableItem] = useState(null); 
  const { openSnackbar } = useSnackbar();
  const [courseSectionList, setCourseSectionList] = useState([]);
  const [assignedCoursesList, setAssignedCoursesList] = useState([]);
  
  const [isviewCoursesDialogOpen, setIsviewCoursesDialogOpen] = useState({
    open: false,
    id: null,
  });
  const getAssignedCoursesList = () => {
    get("course/assigned/get").then((response) => {
      if (response && response.status === "OK") {
        setAssignedCoursesList(response.data);
      } else {
        setAssignedCoursesList([]);
      }
    });
  };
  useEffect(() => {
    getAssignedCoursesList();
  }, []);
  const viewCourses = (id) => {
    setIsviewCoursesDialogOpen({
      open: true,
      id: id,
    });
  };
  const handleDialogBoxClose = () => {
    setIsviewCoursesDialogOpen({
      open: false,
      id: null,
    });
  };
  const handleEditing = (id) => {  
    setEditableItem(id);
    setOpen(true);
  };

  
  let userId = ''
  function getData(data, dataId, name) {
  
    const matchingCourse = data.find((course) => course.courseName == name);
     if (matchingCourse) {
      const lecturerId = matchingCourse.id;
      userId = lecturerId;
    } 
  }
  const courseChange = (id) => {
    if (window.confirm(` Are you sure you want to change Course ?` )) { 

      post(null, `course/${id}/change/request`)
      .then((res) => {
        if (res && res.status === "OK") {
          openSnackbar(res.message);
          
        } else {
          console.log("Something went wrong");
        }
      })
      .catch((err) => console.log(err));
    }
  };

  
  return (
    <>
      <Box>
        <Paper
          elevation={3}
          sx={{
            padding: "8px 16px",
            borderRadius: "16px",
            mb: 2,
          }}
        >
          <Typography variant="h6">Welcome Back, {user.fullName}!</Typography>
        </Paper>

        <Box>
          <Typography variant="h6">Assigned Courses</Typography>
          <Box mt={2}>
            <TableContainer>
              <Table variant="soft" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: "var(--light-dark-color)" }}>Course Name</TableCell>
                    <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>
                      Course Code
                    </TableCell>
                    <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>
                      Section Name
                    </TableCell>
                    <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>
                      Course Hours
                    </TableCell>
                    <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>
                      Availability
                    </TableCell>
                    <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>
                      Duration
                    </TableCell>
                    <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>
                      Course Change
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assignedCoursesList.map((item, i) => (
                    <TableRow key={i}>
                      <TableCell sx={{ color: "#333", fontWeight: "600" }}>
                        {item.name}
                      </TableCell>
                      <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>
                        {item.code}
                      </TableCell>
                      <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>
                        {item.sectionName}
                      </TableCell>
                      <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>
                        {item.duration}
                      </TableCell>
                      <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>
                     
                      {getData(courseSectionList, item.sectionId, item.name)}
                      <Button
                        variant="outlined"
                        sx={{
                          width: "80px",
                          fontSize: "12px",
                          color: "var(--primary-theme-color)",
                          borderColor: "var(--primary-theme-color)",
                          backgroundColor: "var(--secondary-theme-color)",
                          "&:hover": {
                            backgroundColor: "var(--hover-background-color)",
                            borderColor: "var(--hover-border-color)",
                          },
                        }}
                        onClick={() => handleEditing(userId)}
                      >
                        Edit
                      </Button>
                    
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          sx={{
                            width: "100px",
                            height: "25px",
                            color: "white",
                            fontSize: "10px",
                            backgroundColor:
                              "var(--primary-theme-color, #740025)",
                            "&:hover": {
                              backgroundColor: "var(--primary-theme-color)",
                            },
                          }}
                          onClick={() => viewCourses(item.id)}
                        >
                          View courses
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          sx={{
                            width: "130px",
                            height: "25px",
                            color: "white",
                            fontSize: "10px",
                            backgroundColor:
                              "var(--primary-theme-color, #740025)",
                            "&:hover": {
                              backgroundColor: "var(--primary-theme-color)",
                            },
                          }}
                          onClick={() => courseChange(item.id)}
                        >
                          Change Course
                        </Button>
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <AddCourseSectionDialog
        open={open}
        handleClose={() => {
          setOpen(false);
          setEditableItem(null);
        }}
       
        editableItemid={editableItem}
      />
      </Box>

      <ViewCoursesDialog
        open={isviewCoursesDialogOpen.open}
        handleClose={handleDialogBoxClose}
        id={isviewCoursesDialogOpen.id}
      />
    </>
  );
}
