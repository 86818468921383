import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { post } from "../../Config/ApiConfig";

const ChangePasswordDialog = ({ open, handleClose }) => {
  const [formData, setformData] = useState({
    oldPassword: "",
    newPassword: "",
    conformPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handleChange = (e) => {
    const { value, name } = e.target;
    setformData({
      ...formData,
      [name]: value,
    });
    if (name === "conformPassword" && formData.newPassword !== value) {
      setConfirmPasswordError("conform password must match to new password");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    post(
      null,
      `changePassword?newPassword=${formData.newPassword}&oldPassword=${formData.oldPassword}`
    ).then((response) => {
      if (response && response.status === "OK") {
        handleDialogClose();
      }
    });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleDialogClose = () => {
    setformData({
      oldPassword: "",
      newPassword: "",
      conformPassword: "",
    });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      component={"form"}
      onSubmit={handleSubmit}
      PaperProps={{
        sx: {
          backgroundColor: "#E5E4E2",
          width: "500px",
        },
      }}
    >
      <DialogTitle>Change Password</DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <TextField
              label="Old password"
              type={showPassword ? "text" : "password"}
              name="oldPassword"
              value={formData.oldPassword}
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="New password"
              name="newPassword"
              type={showPassword ? "text" : "password"}
              value={formData.newPassword}
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              label="Confirm password"
              name="conformPassword"
              type={showPassword ? "text" : "password"}
              value={formData.conformPassword}
              onChange={handleChange}
              fullWidth
              error={!!confirmPasswordError}
              helperText={confirmPasswordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose} sx={{ color: "black" }}>
          Cancel
        </Button>
        <Button
          type="submit"
          sx={{ color: "black" }}
          disabled={
            !(
              formData.oldPassword !== "" &&
              formData.newPassword !== "" &&
              formData.conformPassword !== "" &&
              !confirmPasswordError
            )
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;
