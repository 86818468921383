import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import { get, post } from "../../Config/ApiConfig";

export default function AddSpecializationDialog({ open, handleClose }) {
  const [courseList, setCourseList] = useState([]);

  const handleCloseDialog = (event, reason) => {
    if (reason !== "backdropClick") {
      setCourseList([]);
      handleClose();
    }
  }; 
  
  const getCoursesList = () => {
    get(`courses/specialization/get`).then((response) => {
      if (response && response.status === "OK") {
        setCourseList(response.data);
      } else setCourseList([]);
    });
  };
  useEffect(() => {
    open && getCoursesList();
  }, [open]);

  const addCourseToSpacilizationList = (id) => {
  
    post(null, `specialization/${id}/add`).then((response) => {
      if (response && response.status === "OK") {
        setCourseList((prev) => prev.filter((item) => item.id !== id));
      }
    });
  };
  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="view-courses-detail-dialog"
      open={open}
      sx={{
        ".MuiPaper-root": {
          minHeight: "400px",
          width: "600px",
        },
      }}
    >
      <DialogTitle
        sx={{ borderBottom: "1px solid var(--primary-theme-color)", mb: 2 }}
      >
        Add Your New Specialization
      </DialogTitle>
      <DialogContent>
        {courseList.length > 0 ? (
          <>
            <TableContainer>
              <Table variant="soft" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: "var(--light-dark-color)" }}>
                      Course Name
                    </TableCell>

                    <TableCell
                      align="center"
                      sx={{ color: "var(--light-dark-color)" }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {courseList.map((item, i) => (
                    <TableRow key={i}>
                      <TableCell sx={{ color: "#333", fontWeight: "600" }}>
                        {item.name}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          sx={{
                            width: "100px",
                            height: "25px",
                            color: "white",
                            fontSize: "10px",
                            backgroundColor:
                              "var(--primary-theme-color, #740025)",
                            "&:hover": {
                              backgroundColor: "var(--primary-theme-color)",
                            },
                          }}
                          onClick={() => addCourseToSpacilizationList(item.id)}
                        >
                          Add
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <>
            <Typography variant="h5" align="center">
              No Data
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            mr: 2,
            backgroundColor: "var(--primary-theme-color)",
            padding: "4px 32px",
            float: "right",
            "&:hover": {
              backgroundColor: "var(--primary-theme-color)",
              opacity: 0.9,
            },
          }}
          onClick={handleCloseDialog}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
