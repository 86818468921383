import { useEffect } from "react";
import Sidebar from "../../Common/Sidebar/Sidebar";
import { Outlet, useLocation } from "react-router-dom";
import { Container, Grid } from "@mui/material";
import Calendar from "../../Common/Calender/Calender";
import NavigationBar from "../../Common/Navbar/NavigationBar";

export default function Admin() {
  const location = useLocation();
  // Define routes where Calendar should be displayed
  useEffect(() => {
    console.log("path", location);
  }, [location]);
  const routesWithCalendar = [
    "/admin/dashboard",
    "/admin/courses",
    "/admin/courses/section",
    "/admin/courses/classroom/assign",
    "/lecturer/dashboard",
    "/lecturer/profile",
    "/student/dashboard",
    "/admin/courses/section/adjust-available",
  ];
  const shouldShowCalendar = routesWithCalendar.includes(location.pathname);
  return (
    <Container maxWidth="xl" sx={{ padding: "0 !important" }}>
      <NavigationBar />
      <Grid container>
        <Grid item xs={2}>
          <Sidebar />
        </Grid>
        <Grid item xs={10} mt={3} px={2}>
          {shouldShowCalendar ? (
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Outlet />
              </Grid>
              <Grid item xs={3}>
                <Calendar />
              </Grid>
            </Grid>
          ) : (
            <>
              <Outlet />
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
