import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, styled, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { get } from "../../../Config/ApiConfig";
const CustomTableContainer = styled(TableContainer)`
  max-height: 400px;
  overflow-y: auto;

  /* Customize the scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
`;

export default function ViewSectionOfCourses() {
  const { courseid } = useParams();
  const [courseList, setCourseList] = useState([]);

  const getCoursesList = () => {
    if (courseid)
      get(`get/section/details/${courseid}/course`).then((response) => {
        if (response && response.status === "OK") {
          setCourseList(response.data);
        } else setCourseList([]);
      });
  };
  useEffect(() => {
    getCoursesList();
    console.log(courseid);
  }, []);
  console.log('course list : ', courseList);
  function timeConverter(milliseconds , venue) {
    
    const date = new Date(milliseconds);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1; // Months are zero-based
    const day = date.getUTCDate();
  
    // Format the date and time
    const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  
    return `${formattedTime} ${venue}`;
  }
  
  const extractHours = (date1) => {
    console.log("date 1 : ", date1);
    const hours1 = new Date(date1).getHours() % 12 || 12;
    console.log('Date : ', new Date(date1).getHours() );
   
    const mapping = {
        8: 2, 9: 3, 10: 4, 11: 5, 12: 6,
        2: 8, 3: 9, 4: 10, 5: 11
    };
    const resultHours = mapping[hours1] || 0;
    console.log("REsult : ", mapping[11])

    console.log(resultHours)
    return { 
        resultHours, 
        
    };
};

    
  return (
    <>
      <Box>
        <Typography variant="h5" color="black" mb={2}>
          Available Section
        </Typography>
        <Box>
          {courseList.length > 0 ? (
            <>
              <Typography variant="body2">Available Section</Typography>
              <CustomTableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>No.</TableCell>
                      <TableCell>Course Name</TableCell>
                      <TableCell align="center">Course Code</TableCell>
                      <TableCell align="center">Section</TableCell>
                      <TableCell align="center">Capacity</TableCell>
                      <TableCell align="center">Available Space</TableCell>
                      <TableCell align="center">Time venue</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {courseList.map((row, index) => (
                      <TableRow key={index + 1}>
                        <TableCell>{index + 1}...</TableCell>
                        <TableCell
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: "#740025",
                              padding: "20px",
                              borderRadius: "50%",
                            }}
                          ></Box>
                          {row.name}
                        </TableCell>
                        <TableCell align="center">{row.courseCode}</TableCell>
                        <TableCell align="center">{row.name}</TableCell>
                        <TableCell align="center">{row.capacity}</TableCell>
                        <TableCell align="center">
                          {row.availableSpace}
                        </TableCell>
                        <TableCell align="center"> 
                          {/* { timeConverter(row.endTime , row.classroomName )} */}
                          {row.days.map((item) => {
                          const { resultHours} = extractHours(row.startTime);
                          return(    
                          <>
                            {item.substring(0, 3)}&nbsp;{resultHours}  &nbsp;{row.classroomName}
            
                          </>
                        )
                        } )}

                        </TableCell>
                        {/* {const totalMinutes = Math.floor(46500000 / (1000 * 60)); // 775 minutes
                          const hours = Math.floor(775 / 60); // 12 hours
                               const minutes = 775 % 60;           // 55 minutes} */}
                        {/* <TableCell align="center"> {Math.floor(row.startTime / (1000 * 60))}</TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CustomTableContainer>
            </>
          ) : (
            <>
              <Typography variant="h6" textAlign="center">
                No Section Available
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
