import { Alert, Snackbar } from "@mui/material";
import { createContext, useContext, useState } from "react";

const SnackbarContext = createContext({
  openSnackbar: () => {},
});
export const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    message: "",
    severity: "severity",
  });
  const openSnackbar = (message, severity) => {
    setSnackbarState({
      message: message,
      severity: severity,
    });
    setOpen(true);
  };
  const closeSnackbar = () => {
    setOpen(false);
  };

  return (
    <>
      <SnackbarContext.Provider value={{ openSnackbar }}>
        {children}
      </SnackbarContext.Provider>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ width: "100%", top: "0 !important" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={snackbarState.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};
