import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddClassroomDialog from "./AddClassroomDialog";
import { get } from "../../../../Config/ApiConfig";
import { Box, Button, Typography } from "@mui/material";

export default function ManageClassroom() {
  const [open, setOpen] = useState(false);
  const [courseSectionList, setCourseSectionList] = useState([]);
  const [editableItem, setEditableItem] = useState(null);

  const searchFilter = () => {
    try {
      get("classrooms/get").then((response) => {
        if (response && response.status === "OK") {
          setCourseSectionList(response.data);
        } else {
          setCourseSectionList([]);
        }
      });
    } catch (error) {
      console.error("Error on Search Filter", error);
    }
  };

  useEffect(() => {
    searchFilter();
  }, []);

  const handleEditing = (id) => {
    setEditableItem(id);
    setOpen(true);
  }; 
  
  return (
    <Box className="course_management_container">
      <Box className="management_table">
        {courseSectionList.length > 0 ? (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>No.</TableCell>
                  <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>Name</TableCell>
                  <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>Capacity</TableCell>
                  <TableCell align="center" sx={{ color: "var(--light-dark-color)" }}>Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {courseSectionList.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.capacity}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        sx={{
                          width: "80px",
                          fontSize: "12px",
                          color: "var(--primary-theme-color)",
                          borderColor: "var(--primary-theme-color)",
                          backgroundColor: "var(--secondary-theme-color)",
                          "&:hover": {
                            backgroundColor: "var(--hover-background-color)",
                            borderColor: "var(--hover-border-color)",
                          },
                        }}
                        onClick={() => handleEditing(row.id)}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h6" color="initial" align="center">
            No data available
          </Typography>
        )}
      </Box>
      <Button
        variant="contained"
        sx={{
          mt: 2,
          mr: 4,
          backgroundColor: "var(--primary-theme-color)",
          padding: "8px 32px",
          borderRadius: "32px",
          float: "right",
          "&:hover": {
            backgroundColor: "var(--primary-theme-color)",
            opacity: 0.9,
          },
        }}
        onClick={() => setOpen(true)}
      >
        Add New Classroom
      </Button>
      <AddClassroomDialog
        open={open}
        handleClose={() => {
          setOpen(false);
          setEditableItem(null);
        }}
        searchFilter={searchFilter}
        editableItemId={editableItem}
      />
    </Box>
  );
}
