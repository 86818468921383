import React, { useState, useEffect } from "react";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Person3Icon from '@mui/icons-material/Person3';
import { Grid } from "@mui/material";
import DashboardCard from "../../../Components/dashboardCard/DashboardCard";
import DashboardCourseCard from "../../../Components/dashboardCard/DashboardCourseCard";

import menuImg from '../../../Assets/Images/menu icon.png';
import menuBarImg from '../../../Assets/Images/second.png';
import MultiplePerson from '../../../Assets/Images/multiplePerson.png';
import mike from '../../../Assets/Images/mike.png';

import { get } from "../../../Config/ApiConfig";

const defaultCardData = [
  {
    title: 'Total Students',
    icon: <Person2OutlinedIcon fontSize="small" />,
    count: 0,
    percentage: 0,
    time: 'month',
  },
  {
    title: 'Total Courses',
    icon: <LibraryBooksIcon fontSize="small" />,
    count: 0,
    percentage: 0,
    time: 'year'
  },
  {
    title: 'Total Lecturer',
    icon: <Person3Icon fontSize="small" />,
    count: 0,
    percentage: 0,
    time: 'year'
  },
];

const additionalCards = [
  {
    icon: menuImg,
    title: 'Manage Courses',
    pageLink: '/admin/courses',
  },
  {
    icon: menuBarImg,
    title: 'Manage Section',
    pageLink: '/admin/courses/section',
  },
  {
    icon: MultiplePerson,
    title: 'Assign Classroom',
    pageLink: '/admin/courses/classroom/assign',
  },
  {
    icon: mike,
    title: 'Manage User Accounts',
    pageLink: '/admin/user/manage',
  },
];

const Dashboard = () => {
  const [cardData, setCardData] = useState(defaultCardData);

  useEffect(() => {
    const getDashboard = async () => {
      try {
        const res = await get('dashboard/get');
        if (res?.status === 'OK') {
          const { totalCourses, totalStudents, totalTeachers, studentDifference, teacherDifference, courseDifference } = res.data;

          const transformedData = [
            {
              title: 'Total Students',
              icon: <Person2OutlinedIcon fontSize="small" />,
              count: totalStudents || 0,
              percentage: studentDifference || 0,
              time: 'month'
            },
            {
              title: 'Total Courses',
              icon: <LibraryBooksIcon fontSize="small" />,
              count: totalCourses || 0,
              percentage: courseDifference || 0,
              time: 'year'
            },
            {
              title: 'Total Lecturer',
              icon: <Person3Icon fontSize="small" />,
              count: totalTeachers || 0,
              percentage: teacherDifference || 0,
              time: 'year'
            },
          ];
          setCardData(transformedData);
        } else {
          console.error('Failed to fetch dashboard data');
        }
      } catch (err) {
        console.error('Error fetching data', err);
      }
    };

    getDashboard();
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        {cardData.map((data, index) => (
          <Grid item key={index} xs={12} sm={6} md={4}>
            <DashboardCard
              data={data}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2} mt={2}>
        {additionalCards.map((data, index) => (
          <Grid item key={index} xs={4} sm={6} md={4}>
            <DashboardCourseCard icon={data.icon} title={data.title} pageLink={data.pageLink} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Dashboard;
