import React, { createContext, useContext, useEffect, useState } from "react";
import { appShortName } from "../Constants/Constants";
import { post } from "../Config/ApiConfig";
import { useNavigate } from "react-router-dom";
const AuthContext = createContext({
  user: null,
  userToken: null,
  login: async () => false,
  handleAuthUserSession: async () => false,
  logout: async () => false,
});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const getStoredUserData = () => {
    const storedUserString = localStorage.getItem(appShortName + "user");
    if (storedUserString !== null) {
      const storedUser = JSON.parse(storedUserString);
      return storedUser;
    }
    return null;
  };

  const [user, setUser] = useState(getStoredUserData());
  const [userToken, setUserToken] = useState(
    localStorage.getItem(appShortName + "token")
  );

  // useEffect(() => {
  //   console.log("user", user);
  // }, [user]);

  const login = async (user) => {
    try {
      const response = await post(user, "auth/login/verify");
      if (response && response.status === "OK") {
        handleAuthUserSession(response.data);
        return {
          status: true,
          message: response.message,
          data: response.data.user_details,
        }; // Indicate successful login
      } else {
        // Handle error
        // You might want to return an error message or throw an error
        console.error("Login failed:", response.message);
        return {
          status: false,
          message: response.message,
        }; // Indicate failed login
      }
    } catch (error) {
      // Handle network error or other exceptions
      console.error("Error during login:", error);
      // You might want to return an error message or throw an error
      return {
        status: false,
        message: "Internel server error",
      }; // Indicate failed login
    }
  };

  const handleAuthUserSession = async (response) => {
    debugger;
    localStorage.setItem(
      appShortName + "user",
      JSON.stringify(response.user_details)
    );
    localStorage.setItem(appShortName + "token", response.token);
    setUser(response.user_details);
    setUserToken(response.token);
    return true;
  };

  const logout = async () => {
    setUser(null);
    setUserToken(null);
    localStorage.removeItem(appShortName + "user");
    localStorage.removeItem(appShortName + "token");
    navigate("/signin");
    return true; // Indicate successful logout
  };

  return (
    <AuthContext.Provider
      value={{ user, userToken, login, handleAuthUserSession, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
