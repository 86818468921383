import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { get, post } from "../../../Config/ApiConfig";

const AddSectionDialog = ({
  open,
  handleClose,
  searchFilter,
  editableItemId,
}) => {
  const [formData, setFormData] = useState({
    code: "",
    duration: "",
    name: "",
    credits: "",
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "credits") {
      if (value <= 0) {
        setError("Credits must be greater than 0");
      } else {
        setError("");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.credits > 0) {
      post(
        formData,
        editableItemId && editableItemId !== null
          ? `course/update?id=${editableItemId}`
          : `course/add`
      )
        .then((res) => {
          if (res && res.status === "OK") {
            console.log("data save successfully");
            handleDialogClose();
            searchFilter();
          } else {
            console.log("something went wrong");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setError("Credits must be greater than 0");
    }
  };
  const getCourseById = (id) => {
    get(`get/course/${id}`).then((response) => {
      if (response && response.status === "OK") {
        setFormData({
          code: response.data.code,
          duration: response.data.duration,
          name: response.data.name,
          credits: response.data.credits,
          status: response.data.status,
        });
      }
    });
  };
  useEffect(() => {
    if (open) {
      if (editableItemId) getCourseById(editableItemId);
    }
  }, [open]);

  const handleDialogClose = () => {
    setFormData({
      code: "",
      duration: "",
      name: "",
      credits: "",
      status: "",
    });
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit,
          sx: {
            backgroundColor: "#E5E4E2",
            width: "500px",
          },
        }}
      >
        <DialogTitle>
          {editableItemId && editableItemId !== null
            ? "Update Coursse"
            : "Add Course"}
        </DialogTitle>
        <Divider orientation="horizontal" />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                required
                id="name"
                name="name"
                label="Name"
                type="text"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                autoFocus
                required
                id="code"
                name="code"
                label="Code"
                type="text"
                value={formData.code}
                onChange={handleChange}
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                autoFocus
                required
                id="duration"
                name="duration"
                label="Duration"
                type="number"
                value={formData.duration}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Min</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoFocus
                required
                id="credits"
                name="credits"
                label="Credits"
                type="number"
                value={formData.credits}
                onChange={handleChange}
                fullWidth
                variant="outlined"
                error={!!error}
                helperText={error}
              />
            </Grid>

            {editableItemId && (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="status_lable">Status</InputLabel>
                  <Select
                    labelId="status_lable"
                    id="name"
                    name="status"
                    value={formData.status}
                    label="Status"
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>Active</MenuItem>
                    <MenuItem value={0}>Deactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "black" }} onClick={handleDialogClose}>
            Cancel
          </Button>
          <Button sx={{ color: "black" }} type="submit">
            {editableItemId && editableItemId !== null ? "Update" : "Add New"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddSectionDialog;
