import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { post } from "../../../Config/ApiConfig";

const AddNewUserDialog = ({
  open,
  handleClose,
  refreshUserList,
  initialValues,
}) => {
  const [formData, setFormData] = useState({
    id: null,
    fullName: "",
    email: "",
    mobileNumber: "",
    role: "",
    address: "",
    password: "",
  });

  useEffect(() => {
    if (initialValues) {
      const { id, fullName, email, mobileNumber, role, address, password } =
        initialValues;
      setFormData({
        id: id || null,
        fullName: fullName || "",
        email: email || "",
        mobileNumber: mobileNumber || "",
        role: role || "",
        address: address || "",
        password: password || "",
      });
    } else {
      setFormData({
        id: null,
        fullName: "",
        email: "",
        mobileNumber: "",
        role: "",
        address: "",
        password: "",
      });
    }
  }, [initialValues]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    
    if (name === "email") {
      const firstFourLetters = value.substring(0, 4); // Get first four letters from email
      const capitalizedFirstLetter = firstFourLetters.charAt(0).toUpperCase(); // Capitalize first letter
      const restOfLetters = firstFourLetters.slice(1); // Get remaining letters
      const generated = capitalizedFirstLetter + restOfLetters + "@123"; // Concatenate with '@123'
      setFormData({
        ...formData,
        [name]: value,
        password: generated,
      });
    } else {
      
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const endpoint = initialValues ? `user/${formData.id}/update` : `user/add`;

    try {
      const response = await post(formData, endpoint);

      if (response && response.status === "OK") {
       
        if (initialValues) {
         
          console.log("User updated successfully", formData);
        } else {
          console.log("User added successfully", formData);
        }
        refreshUserList(); 
        handleDialogClose(); 
      } else {
        console.log("Something went wrong with the API");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const handleDialogClose = () => {
    setFormData({
      id: null,
      fullName: "",
      email: "",
      mobileNumber: "",
      role: "",
      address: "",
      password: "",
    });
    handleClose(); 
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
        sx: {
          backgroundColor: "#E5E4E2",
          width: "500px",
        },
      }}
    >
      <DialogTitle>
        {initialValues ? "Update User" : "Add New User"}
      </DialogTitle>
      <Divider orientation="horizontal" />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              autoFocus
              required
              id="name"
              name="fullName"
              value={formData.fullName}
              label="Full-Name"
              type="text"
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              id="email"
              name="email"
              value={formData.email}
              label="Email"
              type="email"
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              id="mobileNumber"
              name="mobileNumber"
              value={formData.mobileNumber}
              label="Mobile-Number"
              type="text"
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                labelId="role-select-label"
                id="role-select"
                name="role"
                disabled={initialValues !== null}
                value={formData.role}
                label="Role"
                onChange={handleChange}
              >
                <MenuItem value={1}>Lecturer</MenuItem>
                <MenuItem value={2}>Student</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              id="address"
              name="address"
              value={formData.address}
              label="Address"
              type="text"
              onChange={handleChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button sx={{ color: "black" }} onClick={handleDialogClose}>
          Cancel
        </Button>
        <Button sx={{ color: "black" }} type="submit">
          {initialValues ? "Update" : "Add User"}
        </Button>
      </DialogActions>
    </Dialog>

  );
};

export default AddNewUserDialog;

