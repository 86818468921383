import { useAuth } from "./Auth";
import { Navigate, useLocation } from "react-router-dom";

export default function RequiredAuth({ children, userRole }) {
  const location = useLocation();
  const {user} = useAuth();
  if (!user || user.role !== userRole) {
    console.log('you are not authorized');
    return <Navigate to="/signin" state={{ path: location.pathname }} />;
  }
  return children;
}
