import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";

const CustomButton = ({ to, children, ...props }) => {
  return (
    <Button
      variant="contained"
      component={NavLink}
      to={to}
      end
      sx={{
        padding: "16px 32px",
        backgroundColor: "white",
        color: "var(--primary-theme-color)",
        border: 'var(--primary-theme-color) 2px solid',
        borderRadius: "64px",
        "&:hover, &.active": {
          backgroundColor: "var(--primary-theme-color)",
          color: 'white !important',
        },
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
export default function CourseIndex() {
  return (
    <>
      <Box className="management_btns" mb={3}>
        <Grid container>
          <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center'}}>
            <CustomButton to="/admin/courses/section">Manage Sections</CustomButton>
          </Grid>
          <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center'}}>
            <CustomButton to="/admin/courses/classroom/assign">Assign Classrooms</CustomButton>
          </Grid>
          <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center'}}>
            <CustomButton to="/admin/courses/section/adjust-available">Adjust Availablity</CustomButton>
          </Grid>
        </Grid>
      </Box>
      <Outlet />
    </>
  );
}
