import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { get } from "../../../Config/ApiConfig";

export default function ViewCoursesDialog({ open, handleClose, id }) {
  const [courseDetails, setCourseDetails] = useState(null);

  useEffect(() => {
    const getCourseDetails = () => {
      get(`get/course/${id}`).then((response) => {
        if (response && response.status === "OK") {
          setCourseDetails(response.data);
        } else {
          setCourseDetails(null);
        }
      });
    };
    open && id !== null && getCourseDetails();
  }, [open]);

  const handleCloseDialog = (event, reason) => {
    if (reason !== 'backdropClick'){
      setCourseDetails(null);
      handleClose();
    }
  };
  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="view-courses-detail-dialog"
      open={open}
      sx={{
        ".MuiPaper-root": {
          minHeight: "400px",
          width: "600px",
        },
      }}
    >
      <DialogTitle
        sx={{ borderBottom: "1px solid var(--primary-theme-color)", mb: 2 }}
      >
        {courseDetails && courseDetails.name}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={6} mb={2}>
            <Typography variant="body2" color={"var(--primary-theme-color)"}>
              Code
            </Typography>
            <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
              {courseDetails && courseDetails.code}
            </Typography>
          </Grid>
          <Grid item xs={6} mb={2}>
            <Typography variant="body2" color={"var(--primary-theme-color)"}>
              Duration
            </Typography>
            <Typography variant="body1" sx={{ textTransform: "lowercase" }}>
              {courseDetails && courseDetails.duration} Min
            </Typography>
          </Grid>
          <Grid item xs={6} mb={2}>
            <Typography variant="body2 " color={"var(--primary-theme-color)"}>
              Status
            </Typography>
            <Typography variant="body1" sx={{ textTransform: "uppercase" }}>
              {courseDetails && courseDetails.status === 1
                ? "Active"
                : "Inactive"}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          sx={{
            mr: 2,
            backgroundColor: "var(--primary-theme-color)",
            padding: "4px 32px",
            float: "right",
            "&:hover": {
              backgroundColor: "var(--primary-theme-color)",
              opacity: 0.9,
            },
          }}
          onClick={handleCloseDialog}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
