import { styled, TableContainer } from "@mui/material";

const CustomTableContainer = styled(TableContainer)`
  max-height: 400px;
  overflow-y: auto;

  /* Customize the scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
`;

export default CustomTableContainer;