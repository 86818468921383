import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Box,
  Pagination,
  Button,
  Checkbox,
  PaginationItem,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { post } from "../../../Config/ApiConfig";
import ScheduleTable from "../../../Components/scheduleTable/ScheduleTable";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

export default function CourseSchedule() {
  const location = useLocation();
  const navigate = useNavigate();
  const [scheduleList, setScheduleList] = useState([]);
  // const [days, setDays] = useState([]);
  // const [timeSlots, setTimeSlots] = useState([]);
  const [currentpage, setCurrentPage] = useState(0);
  const [currentSelectedSchedule, setCurrentSelectedSchedule] = useState(null);
  const isFirstMount = useRef(false);
  const handleCheckboxChange = (event, currnetSchedule) => {
    console.log(
      "currentpage, currentSelectedSchedule",
      currentpage,
      currentSelectedSchedule
    );
    if (event.target.checked) {
      setCurrentSelectedSchedule(currnetSchedule);
    } else {
      setCurrentSelectedSchedule(0);
    }
  };

  const handleCurrentPageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getSchedule = () => {
    const data = {
      courseIds: location.state,
    };
    post(data, "schedule/get").then((response) => {
      if (response && response.status === "OK") {
        setScheduleList(response.data);
        // setDays(response.data.days);
        // setTimeSlots(response.data.timeSlots);
        // console.log('first dsklji', response.data, response.data.length)
        if (response.data.length >= 0) {
          setCurrentPage(1);
        }
      }
    });
  };
  useEffect(() => {
    if (!isFirstMount.current) {
      isFirstMount.current = true;
      getSchedule();
    }
  }, []);

  const handleRegistration = (currentSchedule) => {
    const data = {
      days: scheduleList[currentSchedule - 1].days,
      timeSlots: scheduleList[currentSchedule - 1].timeSlots,
      schedule: scheduleList[currentSchedule - 1].schedules,
      courseIds: location.state,
    };
    post(data, "schedule/registration/add").then((response) => {
      if (response && response.status === "OK") {
        navigate("/student/my-schedules", { replace: true });
      }
    });
  };
  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h5" sx={{ mb: 1 }}>
            Available Schedule
          </Typography>
          <Box>
            <Pagination
              count={scheduleList.length}
              sx={{
                ".MuiPaginationItem-text.MuiPaginationItem-page": {
                  backgroundColor: "unset",
                  border: "2px solid var(--light-dark-color)",
                  boxSizing: "border-box",
                  "&.Mui-selected": {
                    backgroundColor: "var(--primary-theme-color)",
                    color: "white",
                    border: "unset",
                  },
                },
              }}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
                  {...item}
                />
              )}
              page={currentpage}
              onChange={handleCurrentPageChange}
            />
          </Box>
        </Box>
        <Checkbox
          sx={{
            "& .MuiIconButton-root": {
              backgroundColor: "#740025",
            },
            "&.Mui-checked": {
              color: "#740025",
            },
          }}
          size="small"
          onChange={(event) => handleCheckboxChange(event, currentpage)}
          checked={
            currentSelectedSchedule !== null &&
            currentSelectedSchedule !== 0 &&
            currentpage !== null &&
            currentpage !== 0 &&
            currentSelectedSchedule === currentpage
          }
        />
        {currentpage !== null && scheduleList[currentpage - 1] ? (
          <>
            <ScheduleTable
              days={scheduleList[currentpage - 1].days}
              timeSlots={scheduleList[currentpage - 1].timeSlots}
              schedule={scheduleList[currentpage - 1].schedules}
            />
            <Button
              variant="contained"
              sx={{
                my: 2,
                mr: 4,
                backgroundColor: "var(--primary-theme-color)",
                padding: "8px 32px",
                borderRadius: "32px",
                float: "right",
                "&:hover": {
                  backgroundColor: "var(--primary-theme-color)",
                  opacity: 0.9,
                },
              }}
              disabled={
                currentSelectedSchedule === null ||
                currentSelectedSchedule === 0
              }
              onClick={() => handleRegistration(currentSelectedSchedule)}
            >
              Register
            </Button>
          </>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}
