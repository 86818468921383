import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Grid,
} from "@mui/material";
import { useAuth } from "../../Common/Auth";
import { api_delete, get } from "../../Config/ApiConfig";
import AddSpecializationDialog from "../lecturer/AddSpecializationDialog";

export default function Profile() {
  const { user } = useAuth();
  const [specializedCoursesList, setSpecializedCoursesList] = useState([]);
  const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false);
  const handleDelete = (id) => {
    api_delete(`specialization/${id}/remove`).then((response) => {
      if (response && response.status === "OK") {
        setSpecializedCoursesList((prev) =>
          prev.filter((item) => item.id !== id)
        );
      }
    });
  };

  const getSpecializedList = () => {
    get("specialization/get").then((response) => {
      if (response && response.status === "OK") {
        setSpecializedCoursesList(response.data);
      } else setSpecializedCoursesList([]);
    });
  };
  useState(() => {
    if (user.role === 1) getSpecializedList();
  }, []);

  const handleDialogClose = () => {
    console.log("dialog close");
    getSpecializedList();
    setIsDialogBoxOpen(false);
  };
  return (
    <>
      <Box>
        <Paper
          elevation={3}
          sx={{
            padding: "8px 16px",
            borderRadius: "16px",
            mb: 2,
          }}
        >
          <Typography variant="h6">Welcome Back, {user.fullName}!</Typography>
        </Paper>
        <Box>
          <Box mb={3}>
            <Grid container spacing={2}>
              <Grid item>
                <Avatar
                  alt="Remy Sharp"
                  src="/demo-pic20.jpg"
                  sx={{ height: "150px", width: "150px" }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ textTransform: "capitalize" }}
                >
                  {user.fullName}
                </Typography>
                <Typography variant="body1">{user.email}</Typography>
              </Grid>
            </Grid>
          </Box>
          {user.role === 1 && (
            <>
              <Typography variant="h6">Specialized in courses</Typography>
              <Box mt={2}>
                {specializedCoursesList.length > 0 ? (
                  <TableContainer>
                    <Table variant="soft" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ color: "var(--light-dark-color)" }}>
                            Course Name
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "var(--light-dark-color)" }}
                          >
                            Course Code
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "var(--light-dark-color)" }}
                          >
                            Remove
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {specializedCoursesList.map((item, i) => (
                          <TableRow key={i}>
                            <TableCell
                              sx={{ color: "#333", fontWeight: "600" }}
                            >
                              {item.specialization}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ color: "var(--light-dark-color)" }}
                            >
                              {item.courseCode}
                            </TableCell>
                            <TableCell align="center">
                              <Button
                                sx={{
                                  width: "100px",
                                  height: "25px",
                                  color: "white",
                                  fontSize: "10px",
                                  backgroundColor:
                                    "var(--primary-theme-color, #740025)",
                                  "&:hover": {
                                    backgroundColor:
                                      "var(--primary-theme-color)",
                                  },
                                }}
                                onClick={() => handleDelete(item.id)}
                              >
                                remove
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <>
                    <Typography variant="h6" align="center">
                      No Data
                    </Typography>
                  </>
                )}
                <Button
                  variant="contained"
                  sx={{
                    mt: 2,
                    mr: 4,
                    backgroundColor: "var(--primary-theme-color)",
                    padding: "8px 32px",
                    borderRadius: "32px",
                    float: "right",
                    "&:hover": {
                      backgroundColor: "var(--primary-theme-color)",
                      opacity: 0.9,
                    },
                  }}
                  onClick={() => setIsDialogBoxOpen(true)}
                >
                  Add specialization
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>

      <AddSpecializationDialog
        open={isDialogBoxOpen}
        handleClose={handleDialogClose}
      />
    </>
  );
}
