import React, { useEffect, useRef, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddCourseSectionDialog from "./AddCourseSectionDialog";
import { get } from "../../../Config/ApiConfig";
import { Box, Button, Typography } from "@mui/material";
// import TimerIcon from "@mui/icons-material/Timer";
import dayjs from "dayjs";
// import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
// import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const daysMap = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export default function ManageCourseSection() {

  // const extractHours = (date1, date2) => {
  //   const dateObj1 = new Date(date1);
  //   const dateObj2 = new Date(date2);
  //   console.log("Date obj : ", dateObj1);
  //   let hours1 = dateObj1.getHours(); 
  //   const period1 = hours1 >= 12 ? 'PM' : 'AM'; 
  //   hours1 = hours1 % 12 || 12; 
  //   console.log("Hours : ", hours1 , "Period : ", period1);

  //   let hours2 = dateObj2.getHours();
  //   let min1 = dateObj1.getMinutes();
  //   let min2 = dateObj2.getMinutes();

  //   const resultHours = (hours1 >= 10 && hours1 <= 23) ? 
  //   (hours1 - 9) + (min2 <= 50 ? 1 : 0) :
  //   (hours1 >= 0 && hours1 < 10) ? 
  //   (hours1 + 15) + (min2 <= 50 ? 1 : 0) : 
  //   0; 
  //   return { resultHours, hours2 };
  // };
  const extractHours = (date1) => {
    const hours1 = new Date(date1).getHours() % 12 || 12;
    console.log('Hourss : ', hours1);
    const mapping = {
        8: 2, 9: 3, 10: 4, 11: 5, 12: 6,
        2: 8, 3: 9, 4: 10, 5: 11
    };
    const resultHours = mapping[hours1] || 0;
    console.log('Hourss : asdf ', hours1, " ... ", resultHours);
  
    return { 
        resultHours, 
        // formattedTime1: `${hours1}:${min1 < 10 ? '0' + min1 : min1} ${period1}`, 
        // formattedTime2: `${hours2}:${min2 < 10 ? '0' + min2 : min2} ${period2}` 
    };
};


  const [open, setOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [pagination, setPagination] = useState({
    limit: 20,
    page: 1,
  });
  const prevPagination = useRef(0);
  const handlePagination = (event, value) => {
    setPagination((prev) => ({
      ...prev,
      page: value,
    }));
  };
  const [totalPages, setTotalPages] = useState(0);
  const [courseSectionList, setCourseSectionList] = useState([]);
  const searchFilter = () => {
    try {
      get(
        `section/search?keyword=${keyword}&limit=${pagination.limit}&page=${pagination.page}`
      ).then((response) => {
        if (response && response.status === "OK") {
          setTotalPages(response.data.totalPages);
          // if (!itemAdd) {
          setCourseSectionList(response.data.data);
          //   prevPagination.current = 1;
          //   setPagination((prev) => ({
          //     ...prev,
          //     page: 1,
          //   }));
          // } else {
          //   setCourseSectionList((prev) => [...prev, ...response.data.data]);
          // }
        } else {
          setCourseSectionList([]);
        }
      });
    } catch (error) {
      console.error("Error on Search Fiter", error);
    }
  };
  useEffect(() => {
    if (prevPagination.current < pagination.page) {
      prevPagination.current = pagination.page;
      searchFilter();
    }
  }, [pagination]);

  const getUpcomingDate = (dayOfWeek) => {
    const today = dayjs();
    const targetDayNumber = daysMap[dayOfWeek.toLowerCase()];
    const todayDayNumber = today.day();

    let daysToAdd = targetDayNumber - todayDayNumber;
    if (daysToAdd <= 0) {
      daysToAdd += 7;
    }

    return today.add(daysToAdd, "day");
  };

  return (
    <Box className="course_management_container">
      <Box className="management_table">
        {courseSectionList.length > 0 ? (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{ color: "var(--light-dark-color)" }}
                  >
                    No.
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "var(--light-dark-color)" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "var(--light-dark-color)" }}
                  >
                    Course
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "var(--light-dark-color)" }}
                  >
                    Lecturer
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ color: "var(--light-dark-color)" }}
                  >
                    Capicity
                  </TableCell>
                  <TableCell sx={{ color: "var(--light-dark-color)" }}>
                    venue
                  </TableCell>
                  <TableCell sx={{ color: "var(--light-dark-color)" }}>
                    Day/time 
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {courseSectionList.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.courseName}</TableCell>
                    <TableCell align="center">{row.lecturerName}</TableCell>
                    <TableCell align="center">
                      {row.availableSpace}/{row.capacity}
                    </TableCell>
                    {/* Edit from here for day/time and venue */}
                    <TableCell sx={{ textTransform: "captalize", whiteSpace: "pre-wrap",  }}>
                    {row.classroomName}{"\n"}
                    </TableCell>
                    <TableCell sx={{ textTransform: "captalize", whiteSpace: "pre-wrap",  }}>
                      {row.days.map((item) => {
                        const { resultHours} = extractHours(row.startTime);
                        return(    
                        <>
                          {item.substring(0, 3)}&nbsp;{resultHours}  &nbsp;
          
                        </>
                        )
                        } )}
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        ) : (
          <Typography variant="h6" align="center" sx={{ mt: 2 }}>
            No data available
          </Typography>
        )}
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      > */}
      {/* <Pagination
          count={totalPages}
          sx={{
            ".MuiPaginationItem-text.MuiPaginationItem-page": {
              backgroundColor: "unset",
              border: "2px solid var(--light-dark-color)",
              boxSizing: "border-box",
              "&.Mui-selected": {
                backgroundColor: "var(--primary-theme-color)",
                color: "white",
                border: "unset",
              },
            },
          }}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
              {...item}
            />
          )}
          page={pagination.page}
          onChange={handlePagination}
        /> */}
      <Button
        variant="contained"
        sx={{
          mt: 2,
          mr: 4,
          backgroundColor: "var(--primary-theme-color)",
          padding: "8px 32px",
          borderRadius: "32px",
          float: "right",
          "&:hover": {
            backgroundColor: "var(--primary-theme-color)",
            opacity: 0.9,
          },
        }}
        onClick={() => setOpen(true)}
      >
        Add New Section
      </Button>
      {/* </Box> */}
      <AddCourseSectionDialog
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        searchFilter={searchFilter}
        editableItemid={null}
      />
    </Box>
  );
}
